import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Component, Renderer2, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime, filter, fromEvent } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  AppService,
  StorageKeyToken,
  StorageKeyUserPermissions,
} from './core/services/app/app.service';
import { ErrorService } from './core/services/error/error.service';
import { LoadingSpinnerComponent } from './layouts/loading-spinner/loading-spinner.component';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from './core/auth/auth/auth.service';
import { AuthStatus } from './core/auth/auth-status/auth-status.model';
import { CookieService } from 'ngx-cookie-service';
import { AccountService } from './public/account/services/account-service/account.service';

import { EntitySelectComponent } from './components/entity-select/entity-select.component';
import { User } from './feature-modules/users/models/user.model';

/**
 *
 *
 */

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'esr-app-test';

  menuToggle = false;
  menuMinimize = false;
  menuExpand = false;
  loadingToggle = false;

  selectedTheme = 0;
  selectedText = '';
  alertText = '';
  css = '';
  curPage = '';
  accountId = 0;

  hasMenu = false;

  sessionExtensionDebounceMs = 5000;

  version = environment.version || '';

  @ViewChild(EntitySelectComponent) entitySelectComponent: EntitySelectComponent;

  showEntitySelect = false;
  entitySelectPaths = [
    '/app/attachment-manager',
    '/app/home',
    '/app/client',
    '/app/reports',
    '/app/reports/new',
    '/app/controllers',
    '/app/customer-review',
    '/app/customer-review/new',
    '/app/reports/setup',
    '/app/report-phrases',
    '/app/report-phrases/categories',
    '/app/report-templates',
    '/app/draft-reports',
    '/app/customer',
    '/app/checklists',
    '/app/facility',
    '/app/building',
    '/app/report-scheduler',
    '/app/system',
    '/app/trends-report',
    '/app/system-history-report',
    '/app/components',
    '/app/components-layout',
    '/app/system',
    '/app/system-history',
    '/app/inventory',
    '/app/products',
    '/app/notification-center',
    '/app/questionnaires',
  ];

  constructor(
    private translate: TranslateService,
    private appService: AppService,
    private router: Router,
    private overlay: Overlay,
    private errorService: ErrorService,
    private toastrService: ToastrService,
    private authService: AuthService,
    private accountService: AccountService,
    private renderer: Renderer2,
    private cookieService: CookieService
  ) {
    this.setTranslations();
    this.watchSidenav();
    this.watchRoutes();
    this.createSpinner();
    this.watchErrors();
    this.watchLogin();
    this.checkExistingSession();
    this.watchLanguageChange();
  }

  watchLanguageChange() {
    this.appService.languageChange.subscribe((languageCode: string) => {
      this.translate.use(languageCode);
    });
  }

  // Refresh cookies
  watchClicks() {
    const clicks = fromEvent(document, 'click');
    const result = clicks.pipe(debounceTime(this.sessionExtensionDebounceMs));
    const delayMs = 5000;

    // Delay subscribe to avoid interferring with the Login procedure.
    setTimeout(() => {
      result.subscribe((x) => {
        const token = this.cookieService.get(StorageKeyToken);
        const permissionString = this.cookieService.get(StorageKeyUserPermissions);

        if (token !== 'undefined') {
          this.authService.setCookie(StorageKeyToken, token);
        }
        if (permissionString !== 'undefined') {
          this.authService.setCookie(StorageKeyUserPermissions, permissionString);
        }
      });
    }, delayMs);
  }

  checkExistingSession() {
    this.authService.checkEnv();
  }

  applyAppSettings() {
    const accountSettings = this.accountService.accountSettings.getValue();
    const appSettings = accountSettings.appSettings;
    const style = this.renderer.createElement('style');

    this.selectedTheme = appSettings.customization?.alertType || 0;

    this.alertText = appSettings.customization?.alertMessage || '';
    this.css = appSettings.customization?.css || '';

    style.type = 'text/css';
    style.appendChild(this.renderer.createText('/* Custom CSS */'));
    style.appendChild(this.renderer.createText(this.css));
    style.appendChild(this.renderer.createText('/* Custom CSS */'));
    this.renderer.appendChild(document.head, style);
  }

  watchLogin() {
    this.authService.status.subscribe((status: AuthStatus) => {
      const user = status.user;
      this.hasMenu =
        !!status.hasSession && !user?.passwordResetRequired && !!user?.accountId;
      this.menuToggle =
        !!status.hasSession && !user?.passwordResetRequired && !!user?.accountId;

      if (status.hasSession && user) {
        if (user.accountId) {
          this.accountService.getAccountSettings(user.accountId).then(() => {
            this.applyAppSettings();
          });
          this.accountService.getAccountImages(user.accountId);
          this.setIntercom(user);
          this.watchClicks();
          if (this.entitySelectComponent) {
            this.entitySelectComponent.checkUserAccess();
          }
        }
      }
    });
  }

  setIntercom(user: User) {
    const intercom = window['intercomSettings'];
    if (!intercom) {
      return;
    }
    window['Intercom']('boot', {
      app_id: 'cjw9ii1v',
      email: user.email || '',
      user_id: user.id || '',
      name: user.firstName && user.lastName ? user.firstName + ' ' + user.lastName : '',
      phone: user.phoneNumber || '',
      company: { company_id: '1', name: 'Test Company' }, // This is going to be addressed in a later ticket
    });
  }

  watchErrors() {
    this.errorService.displayErrors.asObservable().subscribe((errors: any[]) => {
      if (errors) {
        errors.forEach((er) => {
          this.toastrService.error(er);
        });
      }
    });
  }

  watchRoutes() {
    this.router.events
      .pipe(filter((val) => val instanceof NavigationEnd))
      .subscribe((ev) => {
        ev = ev as NavigationEnd;
        this.curPage = ev.url;
        this.showEntitySelect = !!this.entitySelectPaths.includes(ev.url);
        this.errorService.clear();
        if (ev.url.split('/').includes('app')) {
          this.appService.sidenavToggle.emit(true);
        }
      });
  }

  watchSidenav() {
    this.appService.sidenavToggle.asObservable().subscribe((val) => {
      if (val) {
        this.menuToggle = val;
      }
    });
  }

  setTranslations() {
    this.translate.addLangs(['en_us']);
    this.translate.setDefaultLang('en_us');
    //this.translate.use('en_us');
  }

  createSpinner() {
    const overlayRef = this.overlay.create();
    const spinner = new ComponentPortal(LoadingSpinnerComponent);
    overlayRef.attach(spinner);
  }

  onMenuExpand(val: boolean) {
    this.menuExpand = val;
  }

  onMenuToggle(val: boolean) {
    this.menuMinimize = val;
  }
  onInit() {}
}
