<mat-card class="small-card">
  <h1>
    {{ 'titles.password-reset' | translate }}
  </h1>

  <div id="password-reset-required-form-wrapper">
    <form [formGroup]="passwordResetRequiredForm" (ngSubmit)="submit()">
      <div>
        <mat-form-field appearance="outline">
          <mat-label>{{ 'labels.password-current' | translate }}</mat-label>
          <input matInput password type="password" formControlName="passwordCurrent" />
        </mat-form-field>
        
        <mat-form-field appearance="outline">
          <mat-label>{{ 'labels.password' | translate }}</mat-label>
          <input matInput password type="password" formControlName="password" (input)="onInputChange()"/>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>{{ 'labels.password-confirmation' | translate }}</mat-label>
          <input matInput password type="password" formControlName="passwordConfirmation" (input)="onInputChange()"/>
        </mat-form-field>
      </div>
      
      <div align="end">
        <button mat-raised-button type="submit" [disabled]="!passwordResetRequiredForm.valid" color="primary">
          {{ 'actions.submit' | translate }}
        </button>
      </div>
    </form>

    <app-error-list></app-error-list>

    <div id="password-reset-required-form-validations">
      <div class="validations-heading">
        Your password must:
      </div>

      <!-- <div>
        Have at least 1 lowercase latin letter
      </div>

      <div>
        Have at least 1 uppercase latin letter
      </div>

      <div>
        Have at least 1 number or special character
      </div> -->

      <div>
        <span class="validation-marker validation-invalid" *ngIf="passwordLengthInvalidMin">X</span>
        <span class="validation-marker validation-valid" *ngIf="!passwordLengthInvalidMin">✓</span>
        Minimum 8 characters
      </div>
      
      <div>
        <span class="validation-marker validation-invalid" *ngIf="passwordLengthInvalidMax">X</span>
        <span class="validation-marker validation-valid" *ngIf="!passwordLengthInvalidMax">✓</span>
        Fewer than 35 characters
      </div>

      <!-- <div>
        Not contain your username, first or last name, email address, current or previous password
      </div> -->

      <div>
        <span class="validation-marker validation-invalid" *ngIf="passwordMismatch">X</span>
        <span class="validation-marker validation-valid" *ngIf="!passwordMismatch">✓</span>
        Passwords match
      </div>
    </div>
    
  </div>
</mat-card>