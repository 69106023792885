import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { lastValueFrom } from 'rxjs';
import {
  GraphResponse,
  GraphqlService,
} from 'src/app/core/services/graphql/graphql.service';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/feature-modules/users/services/user.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss'],
})
export class PasswordResetComponent implements OnInit {
  email = new FormControl('', Validators.required);

  submitted = false;

  constructor(
    private userService: UserService,
    private graphService: GraphqlService,
    private toaster: ToastrService,
    private router: Router,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {}

  submit() {
    if (this.email.value) {
      this.submitted = true;
      lastValueFrom(
        this.graphService.mutate<{
          forgotPassword: {
            success: boolean;
          };
        }>({
          mutation: this.userService.mutations.forgotPassword,
          variables: {
            email: this.email.value,
          },
          isPublic: true,
        })
      )
        .then(
          (
            res: GraphResponse<{
              forgotPassword: {
                success: boolean;
              };
            }>
          ) => {
            if (res.data && res.data.forgotPassword.success) {
              const message = this.translate.instant(
                'messages.authorization.temp-password-sent'
              );

              this.router.navigate(['/login']);
              this.toaster.success(message);
            } else {
              this.submitted = false;
            }
          }
        )
        .catch((error) => {
          console.error('Error saving changes', error);
        });
    } else {
      throw new Error('Invalid email value');
    }
  }
}
