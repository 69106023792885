import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { lastValueFrom, Subscription } from 'rxjs';
import { EntityOption } from 'src/app/components/entity-select/models/entity-option.model';
import { AuthStatus } from 'src/app/core/auth/auth-status/auth-status.model';
import { AuthService } from 'src/app/core/auth/auth/auth.service';
import { AppService } from 'src/app/core/services/app/app.service';
import {
  GraphqlService,
  GraphResponse,
} from 'src/app/core/services/graphql/graphql.service';
import { Client } from 'src/app/feature-modules/clients/models/client.model';
import { ClientService } from 'src/app/feature-modules/clients/services/client.service';
import { Customer } from 'src/app/feature-modules/customers/models/customer.model';
import { CustomerService } from 'src/app/feature-modules/customers/services/customer.service';
import { Building } from '../../features/buildings/models/building.model';
import { BuildingService } from '../../features/buildings/services/building.service';
import { Facility } from '../../features/facilities/models/facility.model';
import { FacilityService } from '../../features/facilities/services/facility.service';
import { System } from '../../features/systems/models/system.model';
import { SystemService } from '../../features/systems/services/system.service';
import { EntitySubjects } from '../entity-select/models/entity-subjects.model';
import { FilterByEntityService } from './services/filter-by-entity.service';

interface EntitySelectFieldControl {
  client: boolean;
  customer: boolean;
  facility: boolean;
  building: boolean;
  system: boolean;
}

// -----------

@Component({
  selector: 'app-filter-by-entity',
  templateUrl: './filter-by-entity.component.html',
  styleUrls: ['./filter-by-entity.component.scss'],
})
export class FilterByEntityComponent implements OnInit {
  @Output()
  onEntityChange: EventEmitter<any> = new EventEmitter();

  @Output()
  onCustomerChange: EventEmitter<any> = new EventEmitter();

  /** TODO - deprecate ?  */
  fieldControl: EntitySelectFieldControl = {
    client: true,
    customer: true,
    facility: true,
    building: true,
    system: true,
  };

  selections: EntitySubjects;

  dataOptions = {
    client: <EntityOption[]>[],
    customer: <EntityOption[]>[],
    facility: <EntityOption[]>[],
    building: <EntityOption[]>[],
    system: <EntityOption[]>[],
  };

  // User Type control
  preset = {
    client: false,
    customer: false,
  };

  errors: any[] = [];

  // Dynamic labels
  labels = {
    initial: 'labels.client',
    initialSelect: 'actions.select-client',
  };

  subs: Subscription[] = [];

  constructor(
    private appService: AppService,
    private authService: AuthService,
    private clientService: ClientService,
    private customerService: CustomerService,
    private facilityService: FacilityService,
    private buildingService: BuildingService,
    private systemService: SystemService,
    private graphService: GraphqlService,
    private filterByEntityService: FilterByEntityService
  ) {
    this.selections = this.filterByEntityService.entitySelections;
  }

  ngOnInit(): void {
    this.checkUserAccess();
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  checkUserAccess() {
    const authStatus = this.authService.status.getValue();
    const user = authStatus.user;
    const permissions = user?.permission;
    const hasCustomerAccess = permissions?.administration.customerUsersAccess !== 'NONE';
    const hasClientAccess = permissions?.administration.clientUsersAccess !== 'NONE';
    if (!hasCustomerAccess) {
      this.fieldControl.customer = false;
    }
    if (!hasClientAccess) {
      this.fieldControl.client = false;
    }

    if (user) {
      if (user.customer?.id) {
        if (hasCustomerAccess) {
          this.preset.client = true;
          this.preset.customer = true;
          this.setUserClient(authStatus);
          this.setUserCustomer(authStatus);
        }
      } else if (user.client?.id) {
        if (hasClientAccess) {
          this.preset.client = true;
          this.setUserClient(authStatus);
        }
      } else {
        this.loadClients();
      }
    } else {
      this.resetClient();
    }
  }

  // Allow external components to reset the component values via AppService
  enableResetTrigger() {
    const sub1 = this.appService.quickNavReset
      .asObservable()
      .subscribe((keys: string[]) => {
        this.reset(keys);
      });
    this.subs.push(sub1);
  }

  loadClients(): void {
    this.clientService
      .getClients()
      .then((clients: Client[]) => {
        this.dataOptions.client = clients;
        const selectedClient =
          this.filterByEntityService.entitySelections.client.getValue();
        // If only one client availble preload it
        if (selectedClient || this.dataOptions.client.length === 1) {
          this.onClientSelect(selectedClient || this.dataOptions.client[0]);
        }
      })
      .catch((e: any) => {
        // nothing
      });
  }

  setUserClient(status: AuthStatus): void {
    const id = status.user?.client?.id;
    if (id) {
      // load the clients
      this.loadClients();
    }
  }

  setUserCustomer(status: AuthStatus): void {
    const id = status.user?.customer?.id;
    if (id) {
      this.loadCustomer(id);
      this.loadFacilities(id);

      // customer user has no access to client users
      this.fieldControl.client = false;
    }
  }

  resetFieldControls(): void {
    this.fieldControl = {
      client: true,
      customer: true,
      facility: true,
      building: true,
      system: true,
    };
  }

  /* --- Data Loading functions --- */

  loadClient(id: number) {
    this.clientService
      .getClientWithEntities(id)
      .then((client: Client) => {
        const customers = client.customers || [];
        this.selections.client.next(client);
        this.dataOptions.customer = customers;
      })
      .catch((e: any) => {
        this.errors.push(e);
      });
  }

  loadCustomer(id: number) {
    this.customerService
      .getCustomerWithFacilities(id)
      .then((customer: Customer) => {
        if (customer) {
          this.selections.customer.next(customer);
          this.onCustomerChange.emit(customer);
        }
      })
      .catch((e: any) => {
        this.errors.push(e);
      });
  }

  loadFacilities(customerId: number): void {
    lastValueFrom(
      this.graphService.query<{ facilitys: Facility[] }>({
        query: this.facilityService.queries.list,
        variables: {
          customerId: customerId,
          idList: [],
        },
      })
    )
      .then((res: GraphResponse<{ facilitys: Facility[] }>) => {
        const facilities = res.data.facilitys;
        this.dataOptions.facility = facilities;
      })
      .catch((e) => {
        // Nothing
      });
  }

  loadBuildings(facilityId: number): void {
    lastValueFrom(
      this.graphService.query<{ buildings: any[] }>({
        query: this.buildingService.queries.list,
        variables: {
          facilityId: facilityId,
          idList: [],
        },
      })
    )
      .then((res: GraphResponse<{ buildings: any }>) => {
        const buildings = res.data.buildings || [];
        this.dataOptions.building = buildings;
      })
      .catch((e) => {
        // Nothing
      });
  }

  loadSystems(buildingId: number): void {
    lastValueFrom(
      this.graphService.query<{ systems: System[] }>({
        query: this.systemService.queries.list,
        variables: {
          buildingId: buildingId,
          idList: [],
        },
      })
    )
      //this.systemService.getSystem(id)
      .then((res: GraphResponse<{ systems: System[] }>) => {
        const systems = res.data.systems;
        this.dataOptions.system = systems;
        if (systems.length === 1 && this.fieldControl.system) {
          this.onSystemSelect(systems[0]);
        }
      });
  }

  /* --- Dropdown Select Handlers --- */

  onClientSelect(value: EntityOption | null): void {
    if (!value) {
      this.resetClient();
      return;
    }
    const current = this.appService.subjects.client.getValue();
    if (current || this.dataOptions.client.length === 1) {
      this.reset([
        'customer',
        'facility',
        'building',
        'system',
      ]);
    }
    this.loadClient(value.id);
  }

  onCustomerSelect(value: EntityOption | null): void {
    if (!value) {
      this.resetCustomer();
      return;
    }

    const current = this.appService.subjects.customer.getValue();
    if (current) {
      this.reset(['facility', 'building', 'system']);
    }
    this.loadCustomer(value.id);
  }

  onFacilitySelect(value: Facility | null): void {
    if (!value) {
      this.resetFacility();
      return;
    }
    const current = this.appService.subjects.facility.getValue();
    if (current) {
      // current value, reset downstream controls
      this.reset(['building', 'system']);
    }
    this.appService.setFacility(value as Facility);
    this.loadBuildings(value.id);
  }

  onBuildingSelect(value: EntityOption | null): void {
    if (!value) {
      this.resetBuilding();
      return;
    }

    const current = this.appService.subjects.building.getValue();
    if (current) {
      // current value, reset downstream controls
      this.reset(['system']);
    }
    this.appService.setBuilding(value as Building);
    this.loadSystems(value.id);
  }

  onSystemSelect(value: EntityOption | null): void {
    if (!value) {
      this.resetSystem();
    } else {
      this.appService.setSystem(value as System);
    }
  }

  /* --- State Control functions --- */

  reset(keys: string[]) {
    keys.forEach((key) => {
      this.selections[key]?.next(null);
    });
    this.onEntityChange.emit(keys);
  }

  resetClient() {
    this.reset([
      'client',
      'customer',
      'facility',
      'building',
      'system',
    ]);
  }

  resetCustomer() {
    this.reset([
      'customer',
      'facility',
      'building',
      'system',
    ]);
  }

  resetFacility() {
    this.reset(['facility', 'building', 'system']);
  }

  resetBuilding() {
    this.reset(['building', 'system']);
  }

  resetSystem() {
    this.reset(['system']);
  }
}
