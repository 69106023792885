import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Client } from 'src/app/feature-modules/clients/models/client.model';
import { Customer } from 'src/app/feature-modules/customers/models/customer.model';
import { Building } from 'src/app/features/buildings/models/building.model';
import { Facility } from 'src/app/features/facilities/models/facility.model';
import { System } from 'src/app/features/systems/models/system.model';
import { EntityOption } from '../../entity-select/models/entity-option.model';
import { EntitySubjects } from '../../entity-select/models/entity-subjects.model';

@Injectable({
  providedIn: 'root',
})
export class FilterByEntityService {
  entitySelections: EntitySubjects = {
    client: new BehaviorSubject<Client | null>(null),
    customer: new BehaviorSubject<Customer | null>(null),
    facility: new BehaviorSubject<Facility | null>(null),
    building: new BehaviorSubject<Building | null>(null),
    system: new BehaviorSubject<System | null>(null),
  };

  constructor() {}
}
