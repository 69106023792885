

<div *ngIf="hideBG">
    <ng-container *ngTemplateOutlet="breadcrumbs"></ng-container>
</div>

<mat-card *ngIf="!hideBG">
    <ng-container *ngTemplateOutlet="breadcrumbs"></ng-container>
</mat-card>

<ng-template #breadcrumbs>
    <div class="top-controls d-flex">
        <div class="d-inline-flex" *ngFor="let node of defaultNodes; let last = last">
            <span *ngIf="!node.path" class="align-self-center">{{ node.label | translate }}</span>
            <a *ngIf="node.path" href="" class="align-self-center" [routerLink]="node.path" [queryParams]="node.params">{{ node.label | translate }}</a>
            <mat-icon *ngIf="!last" class="align-self-center">chevron_right</mat-icon>
        </div>
    </div>
</ng-template>