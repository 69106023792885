export const getContentQuery = `
      query AppSetting {
        appSetting {
          ... on AppSettings {
            content {
              id
              welcomeContent
            }
          }
          ... on NotFoundError {
            message
            code
          }
          ... on ConflictError {
            message
            code
          }
          ... on NotAllowed {
            message
            code
          }
          ... on InvalidParametersError {
            message
            code
          }
        }
      }
    `;

export const getWelcomeContentQuery = `
    query Content($accountSettingId: Float!) {
      accountSetting(id: $accountSettingId) {
        ... on AccountSettings {
          appSettings {
            id
            content {
              id
              welcomeContent
            }
          }
        }
      }
    }
    `;

export const getAccountSettingsQuery = `query Query($accountSettingId: Float!) {
      accountSetting(id: $accountSettingId) {
        ... on AccountSettings {
          blacklistedDomains
          emailSubject
          id
          lockout
          marketingEmail
          ordersEmail
          passwordRotation
          supportEmail
          emailSubject
        }
      }
    }
    `;

export const getAccountStatisticsQuery = `
      query Query($accountId: Int!) {
        account(id: $accountId) {
          appSettings {
            statistics {
              labReports
              operatorLogs
              salesReps
              serviceReports
              systemAdmins
              totalClientUsers
              totalClients
              totalControllers
              totalCustomerUsers
              totalCustomers
              visitReports

            }
            licensing {
              salesReps
              systemAdmins
              totalClients
            }
            customization {
              alertMessage
              alertType
              css
              js
            }
            configuration {
              enableVideo
              enableAliquot
              enableAPI
              defaultLoader
              customerAccountNumber
            }
          }
        }
      }
    `;

export const getAccountImagesQuery = `
query AttachmentsByFolder($locationId: Int!, $location: AttachmentLocation!) {
  attachmentsByFolder(locationId: $locationId, location: $location) {
    ... on Attachment {
      description
      extension
      file
      id
      locationId
      locationType
    }
    ... on InvalidParametersError {
      code
      message
    }
    ... on NotAllowed {
      code
      message
    }
    ... on NotFoundError {
      code
      message
    }
  }
}   `;

export const updateWelcomeContentMutation = `
    mutation EditContent($editContentId: Float!, $data: ContentInput!) {
        editContent(id: $editContentId, data: $data) {
          ... on Content {
            welcomeContent
          }
        }
      }
    `;
export const updateAccountSettingsMutation = `
      mutation UpdateAccountSettings($data: AccountSettingInput!, $updateAccountSettingsId: Float!) {
        updateAccountSettings(id: $updateAccountSettingsId, data: $data) {
        ... on AccountSettings {
            blacklistedDomains
            emailSubject
            lockout
            marketingEmail
            ordersEmail
            passwordRotation
            supportEmail
            
          }
        }
        
      }
    `;
export const updateLicensingMutation = `
      mutation UpdateLicensing($data: LicensingInput!, $updateLicensingId: Float!) {
        updateLicensing(data: $data, id: $updateLicensingId) {
          ... on Licensing {
            totalClients
            systemAdmins
            salesReps
          }
        }
      }
      `;
export const updateCustomizationMutation = `
        mutation UpdateCustomization($data: CustomizationInput!, $updateCustomizationId: Float!) {
          updateCustomization(data: $data, id: $updateCustomizationId) {
            ... on Customization {
              alertMessage
              alertType
              css
              js
            }
          }
        }
      `;
export const updateConfigurationMutation = `
        mutation Mutation($data: ConfigurationInput!, $updateConfigurationId: Float!) {
          updateConfiguration(data: $data, id: $updateConfigurationId) {
            ... on Configuration {
              enableAPI
              enableAliquot
              enableVideo
              defaultLoader
              customerAccountNumber
            }
          }
        }
      `;

export const uploadAttachmentMutation = `
  mutation UploadAttachment($data: FileInput!) {
    uploadAttachment(data: $data) {
      ... on Attachment {
        description
        extension
        file
        id
        locationType
        locationId
      }
      ... on InvalidParametersError {
        message
        code
      }
      ... on NotAllowed {
        message
        code
      }
      ... on NotFoundError {
        message
        code
      }
    }
  }
`;
export const deleteAttachment = `
  mutation UploadAttachment($deleteAttachmentId: Int!) {
    deleteAttachment(id: $deleteAttachmentId)
  }
  `;
