import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, map, of, Subject } from 'rxjs';
import { EntityOption } from 'src/app/components/entity-select/models/entity-option.model';
import { Facility, FacilityFull } from '../models/facility.model';
import { BuildingsTestData } from 'src/app/data/buildings.data';
import { FacilitiesTestData } from 'src/app/data/facilities.data';
import {
  GraphqlService,
  GraphResponse,
} from 'src/app/core/services/graphql/graphql.service';
import { User } from 'src/app/feature-modules/users/models/user.model';

export const QPFacilityWithParents = `
  id
  name
  customer {
    id
    name
    client {
      id
      name
    }
  }
  `;

@Injectable({
  providedIn: 'root',
})
export class FacilityService {
  facilities: Subject<Facility[]> = new Subject();

  facilities$ = this.facilities.asObservable();

  queries: {
    list: string;
    getFacility: string;
    getCustomer: string;
  } = {
    list: `query Facilities($customerId: Int, $idList: [Int!]) {
      facilitys(customerId: $customerId, idList: $idList) {
        ... on Facility {
          name
          id
          buildings {
            id
            name
          }
        }
        ... on ConflictError {
          message
          code
        }
        ... on NotFoundError {
          message
          code
        }
        ... on InvalidParametersError {
          message
          code
        }
      }
    }`,
    getFacility: `query Facility($facilityId: Int!) {
      facility(id: $facilityId) {
        ... on Facility {
          id
          name
          notes
          contactInfo {
            fax
            faxExt
            phoneFormat
            phoneNumber
            phoneNumberExt
          }
          timezone {
            id
            name
          }
          address {
            street1
            street2
            street3
            street4
            unit
            city
            region
            zipCode
            country
            id
          }
          addressFormat
          accountNumber
          lat
          lon
          showCountryOnRep
          uomSystem
          customer {
            id
          }
        }
      }
    }`,
    getCustomer: `query Customer($customerId: Int!) {
      customer(id: $customerId) {
        ... on Customer {
          id
          address {
            city
            region
            street1
            street2
            street3
            street4
            unit
            zipCode
          }
          addressFormat
          contactInfo {
            phoneFormat
          }
          name
          timezone {
            id
          }
          contactInfo {
            phoneFormat
          }
        }
      }
    }`,
  };

  mutations: { manageFacility: string } = {
    manageFacility: `mutation ManageFacility($data: ManageFacilityInput!, $addressData: AddressInput) {
      manageFacility(data: $data, addressData: $addressData) {
        ... on Facility {
          id
          name
        }
        ... on InvalidParametersError {
          message
          code
        }
        ... on NotFoundError {
          message
          code
        }
        ... on ConflictError {
          message
          code
        }
        ... on NotAllowed {
          message
          code
        }
      }
    }`,
  };

  constructor(
    private http: HttpClient,
    private graphService: GraphqlService
  ) {}

  getFacilities(customerId: number): Promise<Facility[]> {
    const res = this.http.get(`/api/customer/${customerId}/facilities`).pipe(
      map((val) => {
        const facilities = val as EntityOption[];
        //this.facilities.next(facilities);
        return val as Facility[];
      })
    );
    return lastValueFrom(res);
  }

  getFacility(id: number): Promise<FacilityFull> {
    return lastValueFrom(
      this.graphService
        .query<{ facility: FacilityFull }>({
          query: this.queries.getFacility,
          variables: {
            facilityId: id,
          },
        })
        .pipe(
          map((res: GraphResponse<{ facility: FacilityFull }>) => {
            let ret: any;
            if (res.data && res.data.facility) {
              const facility = res.data.facility;
              ret = facility;
            }
            return ret;
          })
        )
    );
  }

  getFacilityWithParents(id: number): Promise<Facility> {
    return lastValueFrom(
      this.graphService
        .query<{ facility: Facility }>({
          query: `query Facility($facilityId: Int!) {
            facility(id: $facilityId) {
              ... on Facility {
                ${QPFacilityWithParents}
              }
            }
          }`,
          variables: {
            facilityId: id,
          },
        })
        .pipe(
          map((res: GraphResponse<{ facility: Facility }>) => {
            let ret: any;
            if (res.data && res.data.facility) {
              const facility = res.data.facility;
              ret = facility;
            }
            return ret;
          })
        )
    );
  }

  getFacilityAccess(customerId: number, facilityId: number): Promise<User[]> {
    return lastValueFrom(
      this.graphService
        .query<any>({
          query: `query Query($customerId: Float!, $facilityId: Float!) {
          facilityAccess(customerId: $customerId, facilityId: $facilityId) {
            ... on User {
              id
              firstName
              lastName
            }
            ... on InvalidParametersError {
              message
              code
            }
            ... on ForbiddenError {
              message
              code
            }
            ... on NotFoundError {
              message
              code
            }
            ... on ConflictError {
              message
              code
            }
            ... on UnprocessableEntityError {
              message
              code
            }
          }
        }`,
          variables: {
            customerId: customerId,
            facilityId: facilityId,
          },
        })
        .pipe(
          map((res: GraphResponse<{ facilityAccess: User[] }>) => {
            let ret: any;
            if (res.data && res.data.facilityAccess) {
              ret = res.data.facilityAccess;
            }
            return ret;
          })
        )
    );
  }

  delete(id: number): Promise<boolean> {
    return lastValueFrom(
      this.graphService
        .mutate<{ deleteFacility: any }>({
          mutation: `mutation DeleteFacility($deleteFacilityId: Float!) {
          deleteFacility(id: $deleteFacilityId)
        }`,
          variables: {
            deleteFacilityId: id,
          },
        })
        .pipe(
          map((res: GraphResponse<{ deleteFacility: any }>) => {
            let ret = false;
            if (res.errors) {
              throw res.errors;
            }
            if (res.data && res.data.deleteFacility) {
              ret = true;
            }
            return ret;
          })
        )
    );
  }
}
