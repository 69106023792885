import { Component, OnInit } from '@angular/core';
import { Observable, skip } from 'rxjs';
import { AppService } from 'src/app/core/services/app/app.service';

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss'],
})
export class LoadingSpinnerComponent implements OnInit {
  loadingToggle$: Observable<boolean>;

  constructor(private appService: AppService) {}

  ngOnInit(): void {
    this.loadingToggle$ = this.appService.loadingToggle.asObservable();
  }
}
