import { faScrewdriverWrench } from '@fortawesome/free-solid-svg-icons';
import { AppActions } from '../app-actions/app-actions.constant';

const NavItems = [
  AppActions.other.Home,
  AppActions.reports.ReportsCreate,
  AppActions.reports.ReportsView,
  AppActions.other.SetupReports,
  AppActions.other.Graphs,
  AppActions.other.Tools,
  AppActions.other.AdminCenter,
];

export { NavItems };
