import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpEvent,
  HttpRequest,
  HttpHandler,
} from '@angular/common/http';
import { NEVER, Observable } from 'rxjs';
import {
  AppService,
  StorageKeyToken,
  StorageKeyUserPermissions,
} from '../services/app/app.service';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from '../auth/auth/auth.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { IS_PUBLIC_API } from '../constants/is-public-api/is-public-api.constant';

@Injectable()
export class AppRequestInterceptor implements HttpInterceptor {
  systemId = '';

  constructor(
    private cookieService: CookieService,
    private authService: AuthService,
    private router: Router,
    private toastr: ToastrService,
    private appService: AppService
  ) {}

  intercept(
    httpRequest: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.cookieService.get(StorageKeyToken);
    const userPermissionString = this.cookieService.get(StorageKeyUserPermissions);
    const isPublic = httpRequest.context.get(IS_PUBLIC_API);
    let req = httpRequest;

    if (httpRequest.url.includes('graphql') && isPublic === false) {
      if (
        token &&
        token !== 'undefined' &&
        userPermissionString &&
        userPermissionString !== 'undefined'
      ) {
        // Refresh cookies
        this.authService.setCookie(StorageKeyToken, token);
        this.authService.setCookie(StorageKeyUserPermissions, userPermissionString);

        // Decorate request
        req = httpRequest.clone({
          headers: httpRequest.headers.set('Authorization', `Bearer ${token}`),
        });
      } else {
        this.authService.logout();
        this.router.navigate(['login']);
        this.toastr.clear();
        this.toastr.error('Your session has expired. Please log in again.');
        this.appService.loadingToggle.next(false);
        this.appService.entityStore.reset();
        return NEVER;
      }
    }

    if (isPublic) {
      req = httpRequest.clone({
        context: undefined,
      });
    }

    return next.handle(req);
  }
}
