import { Component, Input, OnInit } from '@angular/core';
import { User } from 'src/app/feature-modules/users/models/user.model';

interface AccessItem {
  id: number;
  user: User;
}

@Component({
  selector: 'app-user-assignment',
  templateUrl: './user-assignment.component.html',
  styleUrls: ['./user-assignment.component.scss'],
})
export class UserAssignmentComponent implements OnInit {
  _inactiveUsers: User[] = [];
  _activeUsers: User[] = [];
  _removedUsers: User[] = [];

  selectedInactive: User[] = [];
  selectedActive: User[] = [];

  errors: string[] = [];

  @Input() config: any = {};

  constructor() {}

  ngOnInit(): void {}

  get removedUsers() {
    return this._removedUsers;
  }

  @Input()
  set inactiveUsers(val: User[]) {
    this._inactiveUsers = val;

    this._inactiveUsers.forEach((inactiveUser) => {
      const found = this._activeUsers.find((u) => u.id === inactiveUser.id);
      if (found) {
        this._activeUsers.splice(this._activeUsers.indexOf(found), 1);
        this.errors.push('User found in both arrays. Removing the active user.');
      }
    });
  }

  @Input()
  set activeUsers(val: User[]) {
    this._activeUsers = val;

    this._activeUsers.forEach((activeUser) => {
      const found = this._inactiveUsers.find((u) => u.id === activeUser.id);
      if (found) {
        this._inactiveUsers.splice(this._inactiveUsers.indexOf(found), 1);
        this.errors.push('User found in both arrays. Removing the inactive user.');
      }
    });
  }

  get activeUsers(): User[] {
    return this._activeUsers;
  }

  activate(users: User[]) {
    users.forEach((user) => {
      const found = this._inactiveUsers.find((u) => u.id === user.id);
      if (found) {
        this._inactiveUsers.splice(this._inactiveUsers.indexOf(found), 1);
      }
      this._activeUsers.push(user);
    });
    this.selectedInactive = [];
  }

  deactivate(users: User[]) {
    users.forEach((user) => {
      const found = this._activeUsers.find((u) => u.id === user.id);
      if (found) {
        this._activeUsers.splice(this._activeUsers.indexOf(found), 1);
      }
      this._inactiveUsers.push(user);
      this._removedUsers.push(user);
    });
    this.selectedActive = [];
  }

  moveAll() {
    if (this._inactiveUsers.length >= this._activeUsers.length) {
      this._activeUsers.push(...this._inactiveUsers);
      this._inactiveUsers = [];
    } else {
      this._inactiveUsers.push(...this._activeUsers);
      this._activeUsers = [];
    }
  }
}
