import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

import { AuthStatus } from 'src/app/core/auth/auth-status/auth-status.model';
import { User, UserType } from 'src/app/feature-modules/users/models/user.model';
import {
  StorageKeyToken,
  StorageKeyUser,
  StorageKeyUserPermissions,
} from '../../services/app/app.service';
import { ErrorService } from '../../services/error/error.service';
import { GraphqlService, GraphResponse } from '../../services/graphql/graphql.service';
import { HttpClient, HttpContext, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

import { QPPermissionValues } from '../../queries/permissions/permissions.query';
import { IS_PUBLIC_API } from '../../constants/is-public-api/is-public-api.constant';
import { StorageKeyEntities } from 'src/app/components/entity-select/entity-select.component';

export interface AuthResponse {
  token: string;
  user: User;
  code?: string;
}

const authPayload = `... on AuthPayload {
  token
  user {
    email
    firstName
    id
    language
    accounts {
      id
    }
    lastName
    language
    passwordResetRequired
    startPage
    status
    type
    phoneNumber
    phoneFormat
    client {
      id
      name
    }
    customer {
      id
      name
    }
    closingSigImage
    closingSigStr
    permission {
      id
      name
      ${QPPermissionValues}
    }
  }
}`;

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  status = new BehaviorSubject<AuthStatus>({
    userFound: false,
    hasSession: false,
  });
  status$ = this.status.asObservable();

  onLogin = new EventEmitter<AuthStatus>();
  onLogout = new EventEmitter<AuthStatus>();

  constructor(
    private cookieService: CookieService,
    private errorService: ErrorService,
    private router: Router,
    private httpClient: HttpClient,
    private graphService: GraphqlService
  ) {}

  getLogin(vars: { email: string; password: string }) {
    return lastValueFrom(
      this.httpClient.post<GraphResponse<{ login: AuthResponse }>>(
        `${environment.apiBase}/graphql`,
        {
          query: `mutation Login($password: String!, $email: String!) {
          login(password: $password, email: $email) {
            ${authPayload}
            ... on UnauthorizedError {
              message
              code
            }
            ... on ForbiddenError {
              message
              code
            }
          }
        }`,
          variables: vars,
        },
        {
          context: new HttpContext().set(IS_PUBLIC_API, true),
        }
      )
    )
      .then((res: GraphResponse<{ login: AuthResponse }>) => {
        if (res.data.login && !res.data.login.code) {
          const login = res.data.login;
          this.setCookie(StorageKeyToken, login.token);
          this.setUser(login.user);
          this.checkAccounts(login);
        } else {
          this.errorService.log(res.data.login);
        }
      })
      .catch((e: HttpErrorResponse) => {
        this.errorService.log(e, true);
      });
  }

  checkRole(roles: string[]) {
    const status = this.status.getValue();
    const allow = !!roles.find((r) => r === status.user?.type);
    return allow;
  }

  getUser(): User | null {
    const status = this.status.getValue();
    const user = status.user;

    return user || null;
  }

  // To Do - Deprecate the name 'check' and use 'getPermissions' instead
  getPermissions() {
    const status = this.status.getValue();
    const permissions = status.user?.permission;

    if (typeof permissions === 'undefined') {
      throw new Error('Permissions Undefined');
    }

    return permissions;
  }

  getPermission(accessParam: string): string {
    let res = '';
    const user = this.status.getValue().user;
    const permissions = user?.permission;

    if (permissions && accessParam) {
      const params = accessParam.split('.');
      if (params.length === 2) {
        const permCat = permissions[params[0]];
        const perm = permCat[params[1]] ?? '';

        res = perm;
      }
    }
    return res;
  }

  unsetPasswordResetRequired() {
    const status = this.status.getValue();
    const user = status.user;
    if (user) {
      user.passwordResetRequired = false;
      // Updates the current auth state, ensuring the app has the up-to-date status
      this.status.next(status);
    } else {
      const error = new Error('Error unsetting password-reset-required');
      this.errorService.log(error);
    }
  }

  checkEnv() {
    const userString = localStorage.getItem(StorageKeyUser) || 'undefined';
    const userPermsString = this.cookieService.get(StorageKeyUserPermissions);
    const token = this.cookieService.get(StorageKeyToken);

    let user: User;
    let permission;

    const status = {
      userFound: false,
      hasSession: false,
      user: {} as User,
    };

    if (userPermsString && userPermsString !== 'undefined') {
      permission = JSON.parse(userPermsString);
    }

    if (userString && userString !== 'undefined') {
      user = JSON.parse(userString) as User;
      if (permission) {
        user.permission = permission;
      } else {
        this.logout();
      }

      if (user && token && token !== 'undefined') {
        status.userFound = true;
        status.hasSession = true;
        status.user = user;

        setTimeout(() => {
          this.login({ user, token } as AuthResponse);
        });
      }
    }
  }

  checkAccounts(res: AuthResponse) {
    const user = res.user;
    const hasSingleAccount = user.accounts?.length == 1;

    if (hasSingleAccount) {
      user.accountId = user.accounts?.at(0).id;
      this.login(res, true);
    } else {
      this.loadAccounts(user);
    }
  }

  setUser(user: User) {
    const userLite = {
      ...user,
    } as any;
    const permissions = {
      ...user.permission,
    };
    delete userLite.permission;

    localStorage.setItem(StorageKeyUser, JSON.stringify(userLite));
    this.setCookie(StorageKeyUserPermissions, JSON.stringify(permissions));
  }

  login(res: AuthResponse, forward: boolean = false) {
    const token: string = res.token as string;
    const user: User = res.user as User;
    const forwardPath = '/app/home';

    this.setCookie(StorageKeyToken, token);
    this.setUser(res.user);

    const status: AuthStatus = {
      userFound: true,
      hasSession: true,
      user,
    };

    this.status.next(status);
    this.onLogin.emit(status);
    if (forward) {
      this.router.navigate([forwardPath]);
    }
  }

  loadAccounts(user: User) {
    const accountPath = '/select-account';
    if (user) {
      if (user.type == UserType.ALIQUOT_ADMIN) {
        lastValueFrom(
          this.graphService.query({
            query: `query AllAccounts {
              allAccounts {
                id
                name
              }
            }`,
            isPublic: true,
          })
        ).then((res: any) => {
          if (res.data && res.data.allAccounts) {
            const accounts = res.data.allAccounts;
            // Populate User Accounts
            user.accounts = res.data.allAccounts;
            this.status.next({
              userFound: true,
              hasSession: true,
              user,
            });
            if (accounts.length === 1) {
              //user.accountId = accounts?.at(0).id;
              this.chooseAccount(accounts[0].id, user);
            } else {
              this.router.navigate([accountPath]);
            }
          }
        });
      }
    }
  }

  chooseAccount(accountId: number, user: User) {
    lastValueFrom(
      this.graphService.mutate({
        mutation: `mutation SelectAccount($userId: Float!, $accountId: Float!) {
          selectAccount(userId: $userId, accountId: $accountId) {
            ${authPayload}
          }
        }`,
        variables: {
          accountId,
          userId: user.id,
        },
      })
    ).then((res: any) => {
      if (res.data.selectAccount) {
        const newStatus = res.data.selectAccount as AuthResponse;
        newStatus.user.accountId = accountId;
        if (user.accounts) {
          newStatus.user.accounts = [...user.accounts];
        }
        this.login(newStatus, true);
      }
    });
  }

  logout() {
    const status = {
      hasSession: false,
      userFound: false,
      user: undefined,
    };
    this.status.next(status);
    this.onLogout.emit(status);
    localStorage.removeItem(StorageKeyUser);

    this.cookieService.delete(StorageKeyToken, '/');
    this.cookieService.delete(StorageKeyUserPermissions, '/');
    this.cookieService.delete(StorageKeyEntities, '/');
  }

  setCookie(key: string, token: string) {
    const expireMinutes = 30;
    const d: Date = new Date();
    d.setTime(d.getTime() + expireMinutes * 60 * 1000);
    this.cookieService.set(key, token, d, '/');
  }
}
