import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-pdf-upload',
  templateUrl: './pdf-upload.component.html',
  styleUrls: ['./pdf-upload.component.scss'],
})
export class PdfUploadComponent implements OnInit {
  @Input() multiple = false;
  @Input() name: string;
  @Input() abbr: string;
  @Input() required = false;
  @Input() file = '';
  @Output() addNewFile = new EventEmitter<any>();
  files: any[] = [];
  currentPreviews: string[] = [];
  filesData: any = {};

  constructor() {}

  ngOnInit(): void {}

  onFilesDropped($event: any) {
    this.generateFileURI($event, false);
  }

  onRemoveFile(i: number, name: string) {
    this.files.splice(i, 1);
    this.filesData['files'] = this.filesData['files'].filter(
      (file: any) => file.name !== name
    );
    this.addNewFile.emit(this.filesData);
  }

  onRemoveLoadedFile() {
    this.file = '';
    // TODO: Hit delete mutation to remove file from DB
  }

  generatePreview(name: string, i: number) {
    const previewContainer = document.querySelector(`.${name}-${i}`);
    if (this.currentPreviews.includes(`${name}-${i}`) && previewContainer) {
      previewContainer.innerHTML = '';
      this.currentPreviews.splice(this.currentPreviews.indexOf(`${name}-${i}`), 1);
    } else {
      const file = this.files[i];
      const fileUrl = window.URL.createObjectURL(file);
      const iframe = document.createElement('iframe');
      iframe.setAttribute('src', fileUrl);
      previewContainer?.appendChild(iframe);
      previewContainer?.classList.add('previewContainer');
      this.currentPreviews.push(`${name}-${i}`);
    }
  }

  async generateFileURI(event: any, isSelectMode = true) {
    const filesData: any = { files: [] };

    // When files are dragged and dropped, the files are
    // the event itself
    const files = isSelectMode ? event.target.files : event;

    if (this.multiple) {
      this.files = [];
      for (let i = 0; i < files.length; i++) {
        if (!this.files.some((file) => file.name === files[i].name)) {
          this.files.push(files[i]);
        }
      }
    } else {
      this.files = [];
      this.files.push(files[0]);
    }

    // Reset the value so that the change event is triggered if a
    // user deletes a file and then tries to re-attach the same file
    const fileInput = document.querySelector('.fileInput') as HTMLInputElement;
    fileInput.value = '';

    const filePromises = this.files.map((file) => {
      return new Promise<void>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsBinaryString(file);

        reader.onload = function (event) {
          const base64String = window.btoa(event.target?.result as string);
          const data = `data:${file.type};base64,${base64String}`;
          filesData['files'].push({
            data: data,
            name: file.name,
            extension: file.type,
          });

          resolve();
        };

        reader.onerror = function () {
          //console.log("Couldn't read the file");
          reject();
        };
      });
    });

    Promise.all(filePromises)
      .then(() => {
        //console.log('Ready to submit');
        filesData.source = this.abbr;
        this.filesData = filesData;
        this.addNewFile.emit(this.filesData);
      })
      .catch((error) => {
        //console.log('An error occured', error);
      });
  }

  formatBytes(bytes: number, decimals = 2) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = [
      'Bytes',
      'KB',
      'MB',
      'GB',
      'TB',
      'PB',
      'EB',
      'ZB',
      'YB',
    ];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
}
