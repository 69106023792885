import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { UploadFile } from './models/file-upload.model';
import { FileUploadService } from './services/file-upload.service';

interface FileUploadConfig {
  acceptKeys?: string;
  imageOnly?: boolean;
  multiple?: boolean;
  pdfOnly?: boolean;
  validateWidth?: number;
  validateHeight?: number;
  validateSize?: number;
  width?: number;
}

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
class FileUploadComponent implements OnInit {
  files: any[] = [];
  filesData: any;

  _config: FileUploadConfig = {
    multiple: true,
    imageOnly: false,
    pdfOnly: false,
  };

  keysApplication =
    'application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel';
  keysImages = 'image/png,image/jpeg,image/gif,image/bmp,image/webp';

  acceptKeys = `${this.keysApplication},${this.keysImages}`;

  errors: string[] = [];

  @Output() filesReady: EventEmitter<UploadFile[]> = new EventEmitter();
  @Output() filesError: EventEmitter<string[]> = new EventEmitter();

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {}

  @Input()
  set config(val: FileUploadConfig) {
    this._config = val;
    if (this._config.imageOnly) {
      this.acceptKeys = this.keysImages;
    }
    if (this._config.pdfOnly) {
      this.acceptKeys = 'application/pdf';
    }
    if (this._config.acceptKeys) {
      this.acceptKeys = this._config.acceptKeys;
    }
  }

  onFilesDropped($event: any) {
    this.generateFileURI($event, false);
  }

  async generateFileURI(event: any, isSelectMode = true) {
    const filesData: UploadFile[] = [];
    this.errors = [];

    // When files are dragged and dropped, the files are
    // the event itself
    let files = isSelectMode ? [...event.target.files] : ([...event] as UploadFile[]);

    if (!this._config.multiple && files.length > 1) {
      files = [files[0]];
      this.errors.push('Only the first file has been selected.');
    }

    // Reset the value so that the change event is triggered if a
    // user deletes a file and then tries to re-attach the same file
    const fileInput = document.querySelector('.fileInput') as HTMLInputElement;
    fileInput.value = '';

    // Expose context
    const sanitizer = this.sanitizer;
    const config = this._config;
    const errors = this.errors;
    const filesError = this.filesError;

    const filePromises = files.map((file: any, i: number) => {
      return new Promise<void>((resolve, reject) => {
        const reader = new FileReader();

        reader.readAsDataURL(file);
        reader.onload = function (event) {
          const sanitizedUrl = sanitizer.bypassSecurityTrustResourceUrl(
            window.URL.createObjectURL(file)
          );

          const uploadFile: UploadFile = {
            data: event.target?.result,
            name: file.name,
            extension: file.type,
            file,
            fileSize: FileUploadService.formatBytes(file.size),
            imgSrc: sanitizedUrl,
          };

          if (config.validateWidth || config.validateHeight) {
            // Initiate the JavaScript Image object.
            const image = new Image();

            // Validate the File Height and Width.
            image.onload = function () {
              let doReject = false;

              if (config.validateHeight) {
                if (image.height > config.validateHeight) {
                  doReject = true;
                  errors.push(
                    'File does not meet height requirements. Limit: ' +
                      config.validateHeight +
                      'px'
                  );
                }
              }
              if (config.validateWidth) {
                if (image.width > config.validateWidth) {
                  doReject = true;
                  errors.push(
                    'File does not meet width requirements. Limit: ' +
                      config.validateWidth +
                      'px'
                  );
                }
              }

              if (doReject) {
                reject(errors);
              } else {
                filesData.push(uploadFile);
                resolve();
              }
            };

            //Set the Base64 string return from FileReader as source.
            image.src = uploadFile.data;
          } else {
            filesData.push(uploadFile);
            resolve();
          }
        };

        reader.onerror = function () {
          filesError.emit(["Couldn't read the file"]);
          reject();
        };
      });
    });

    Promise.all(filePromises)
      .then(() => {
        this.filesReady.emit(filesData);
        this.filesData = filesData;
      })
      .catch((error) => {
        this.filesError.emit(error);
      });
  }
}

export { FileUploadComponent, FileUploadConfig };
