<div>
  <div class="topnav container-fluid">
    <app-nav></app-nav>
  </div>
  <div>
    <mat-sidenav-container>
      <mat-sidenav
        mode="side"
        position="start"
        [(opened)]="menuToggle"
        [ngClass]="[
          menuMinimize ? 'closed' : 'opened',
          menuExpand ? 'expanded' : '',
          'deep-gradient'
        ]"
      >
        <app-sidenav
          (onMenuToggle)="onMenuToggle($event)"
          (onMenuExpand)="onMenuExpand($event)"
        ></app-sidenav>
      </mat-sidenav>
      <mat-sidenav-content
        [ngClass]="[
          menuToggle && !menuMinimize ? 'opened' : 'closed',
          hasMenu ? 'hasMenu' : ''
        ]"
      >
        <div class="custom-overlay" *ngIf="menuExpand" (click)="menuExpand = false"></div>
        <div class="main container-fluid">
          <!-- Entity Select -->
          <div class="row pad-top" [hidden]="!showEntitySelect">
            <div class="col-sm">
              <app-entity-select></app-entity-select>
            </div>
          </div>
          <div
            id="customAlert"
            *ngIf="selectedTheme != 0 && curPage == '/\login' && alertText.trim() != ''"
            [ngClass]="['alert', 'alert-dismissable', 'alert-' + selectedTheme]"
          >
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <div [innerHTML]="alertText"></div>
          </div>
          <div class="row" style="clear: both">
            <div class="col-sm main-outlet">
              <router-outlet></router-outlet>
            </div>
          </div>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>

  <!--div class="footer container-fluid">
    <p>v: {{ version }}</p>
    <app-footer></app-footer>
  </div-->
</div>
