import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { AppResponse } from 'src/app/core/models/app-response/app-response.model';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(private http: HttpClient) {}

  getDashboards(): Observable<AppResponse> {
    return this.http.get('/api/dashboards').pipe(
      map((val) => {
        return { data: val } as AppResponse;
      })
    );
  }

  getDashboard(id: number): Observable<AppResponse> {
    return this.http.get(`/api/dashboard/${id}`).pipe(
      map((val) => {
        return { data: val } as AppResponse;
      })
    );
  }
}
