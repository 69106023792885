import { Component, EventEmitter, NgZone, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {} from '@angular/google-maps';
import { Address } from 'src/app/core/models/address/address.model';

@Component({
  selector: 'app-address-search',
  templateUrl: './address-search.component.html',
  styleUrls: ['./address-search.component.scss'],
})
export class AddressSearchComponent implements OnInit {
  @Output() onSelect: EventEmitter<any> = new EventEmitter();

  formattedAddress: string;

  placeAddress: google.maps.places.PlaceResult;
  autocomplete: google.maps.places.Autocomplete;

  toggles: any = {
    edit: false,
  };

  addressString = new FormControl('');

  addressForm = new FormGroup({
    street1: new FormControl('', Validators.required),
    street2: new FormControl(''),
    street3: new FormControl(''),
    street4: new FormControl(''),
    unit: new FormControl(''),
    city: new FormControl('', Validators.required),
    region: new FormControl('', Validators.required),
    zipCode: new FormControl('', Validators.required),
  });

  constructor(private zone: NgZone) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.setAutocomplete();
    });
  }

  patchAddress(address: Address, addressString: string) {
    const input = document.getElementById('pacInput') as HTMLInputElement;
    this.addressString.patchValue(addressString);
    this.addressForm.patchValue(address);
    input.focus();

    if (!this.addressForm.valid) {
      this.toggles.edit = true;
    }
  }

  setAutocomplete() {
    const center = { lat: 50.064192, lng: -130.605469 };
    // Create a bounding box with sides ~10km away from the center point
    const defaultBounds = {
      north: center.lat + 0.1,
      south: center.lat - 0.1,
      east: center.lng + 0.1,
      west: center.lng - 0.1,
    };
    const input = document.getElementById('pacInput') as HTMLInputElement;
    const options = {
      bounds: defaultBounds,
      componentRestrictions: { country: 'us' },
      fields: [
        'address_components',
        'formatted_address',
        'geometry',
        'icon',
        'name',
      ],
      strictBounds: false,
      types: ['address'],
    };

    this.autocomplete = new google.maps.places.Autocomplete(input, options);
    this.autocomplete.addListener('place_changed', () => {
      this.onAddressSelect();
    });
  }

  onAddressSelect() {
    this.zone.run(() => {
      this.placeAddress = this.autocomplete.getPlace() as google.maps.places.PlaceResult;
      this.updateFields();
    });
  }

  updateFields() {
    let street1 = '';
    const street2 = '';
    let zipCode = '';
    let city = '';
    let region = '';

    this.placeAddress.address_components?.forEach(
      (component: google.maps.GeocoderAddressComponent) => {
        const componentType = component.types[0];

        switch (componentType) {
          case 'street_number': {
            street1 = `${component.long_name} ${street1}`;
            break;
          }

          case 'route': {
            street1 += component.short_name;
            break;
          }

          case 'postal_code': {
            zipCode = `${component.long_name}${zipCode}`;
            break;
          }

          case 'postal_code_suffix': {
            zipCode = `${zipCode}-${component.long_name}`;
            break;
          }

          case 'locality': {
            city = component.long_name;
            break;
          }

          case 'administrative_area_level_1': {
            region = component.long_name;
            break;
          }
        }
      }
    );
    this.addressForm.patchValue({
      street1,
      street2,
      zipCode,
      region,
      city,
    });

    this.toggles.edit = true;

    this.formattedAddress = this.placeAddress.formatted_address || '';
    this.onSelect.emit({ form: this.addressForm, place: this.placeAddress });
  }
}
