import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { NavItems } from 'src/app/core/constants/nav-items/nav-items.constant';
import { AuthStatus } from 'src/app/core/auth/auth-status/auth-status.model';
import { AuthService } from 'src/app/core/auth/auth/auth.service';
import { System } from 'src/app/features/systems/models/system.model';
import { AppService } from 'src/app/core/services/app/app.service';
import { faRemove, faTableColumns } from '@fortawesome/free-solid-svg-icons';
import { SettingsService } from 'src/app/features/app-settings/services/settings.service';
import { attachmentByFolder } from 'src/app/features/app-settings/models/account-images.model';
import { TranslateService } from '@ngx-translate/core';
import {
  AppLanguages,
  LanguageKeys,
} from 'src/app/core/constants/languages/languages.constant';
import { AccountService } from 'src/app/public/account/services/account-service/account.service';
import { Dictionary } from 'src/app/core/models/dictionary/dictionary.model';
import { DefaultLogo } from 'src/app/core/constants/default-logo/default-logo.constant';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnInit {
  navItems = NavItems;

  authStatus$: Observable<AuthStatus>;

  system$: Observable<System | null>;

  dashboardIcon = faTableColumns;
  resetIcon = faRemove;

  logoPath = '';

  images: Dictionary<attachmentByFolder> = {};
  $images;

  constructor(
    private router: Router,
    private authService: AuthService,
    private appService: AppService,
    private settingsService: SettingsService,
    private accountService: AccountService,
    private translate: TranslateService
  ) {
    this.authStatus$ = this.authService.status$;
    this.system$ = this.appService.viewState.system;
    this.$images = this.accountService.companyLogos.subscribe((images: any) => {
      this.images = images;
    });
  }

  ngOnInit(): void {
    this.setDefaultLogo();
    this.watchLogin();
  }

  setDefaultLogo() {
    this.logoPath = DefaultLogo;
  }

  getImages() {
    // Subscribe - values arrive late
    // Destroy the subscription? The component is only destroyed on reload.
    const imageObj = this.accountService.companyLogos.subscribe((imageObj) => {
      if (imageObj && imageObj['logoCompany']) {
        this.logoPath = imageObj['logoCompany'].file || DefaultLogo;
      } else {
        this.setDefaultLogo();
      }
    });
  }

  watchLogin() {
    this.authService.status.subscribe((status: AuthStatus) => {
      const user = status.user;
      if (status.hasSession && user) {
        const languageKey = LanguageKeys[user.language];
        /* Setting HERE will be the first time the application will apply
        Translate settings to this LayoutModule */
        this.translate.use(languageKey);

        if (user.accountId) {
          this.getImages();
        }
      } else {
        this.setDefaultLogo();
      }
    });
  }

  navigate(path: string): void {
    this.router.navigate([path]);
  }

  openDashboard() {}
}
