import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { catchError, finalize, map, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IS_PUBLIC_API } from '../../constants/is-public-api/is-public-api.constant';
import { AppService } from '../app/app.service';
import { ErrorService } from '../error/error.service';

export interface GraphResponse<T> {
  data: T;
  errors?: any;
  error?: any;
}

export interface GraphRequestOptions {
  query?: string;
  mutation?: string;
  variables?: { [key: string]: any };
  isPublic?: boolean;
}

@Injectable({ providedIn: 'root' })
export class GraphqlService {
  constructor(
    private http: HttpClient,
    private appService: AppService,
    private errorService: ErrorService
  ) {}

  public query<T>(options: GraphRequestOptions): Observable<GraphResponse<T>> {
    setTimeout(() => {
      this.appService.showSpinner();
    });
    // set default options.isPublic to define HttpContext, which is used in the interceptor
    // to determine whether or not to add the Authorization header
    options.isPublic = options.isPublic || false;

    // request
    const obs = this.http
      .post<GraphResponse<T>>(
        `${environment.apiBase}/graphql`,
        {
          query: options.query,
          variables: options.variables,
        },
        {
          context: new HttpContext().set(IS_PUBLIC_API, options.isPublic),
        }
      )
      .pipe(
        map((d) => {
          if (d.errors) {
            //this.errorService.log(d.errors, true);
            if (!d.data) {
              throw d.errors;
            }
          }
          return d as GraphResponse<T>;
        }),
        catchError((err) => {
          this.errorService.log(err, true);
          return of('error', err);
        }),
        finalize(() => {
          this.appService.hideSpinner();
        })
      );
    return obs;
  }

  public mutate<T>(options: GraphRequestOptions): Observable<GraphResponse<T>> {
    setTimeout(() => {
      this.appService.showSpinner();
    });
    // set default options.isPublic to define HttpContext, which is used in the interceptor
    // to determine whether or not to add the Authorization header
    options.isPublic = options.isPublic || false;

    // request
    const obs = this.http
      .post<GraphResponse<T>>(
        `${environment.apiBase}/graphql`,
        {
          query: options.mutation,
          variables: options.variables,
        },
        {
          context: new HttpContext().set(IS_PUBLIC_API, options.isPublic),
        }
      )
      .pipe(
        map((d) => {
          return d as GraphResponse<T>;
        }),
        catchError((err) => {
          this.errorService.log(err, true);
          return of('error', err);
        }),
        finalize(() => {
          this.appService.hideSpinner();
        })
      );
    return obs;
  }
}
