import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ErrorService } from 'src/app/core/services/error/error.service';

@Component({
  selector: 'app-gmap',
  templateUrl: './gmap.component.html',
  styleUrls: ['./gmap.component.scss'],
})
export class GmapComponent implements OnInit {
  map: google.maps.Map;

  addressMarker: google.maps.Marker;

  disable = false;
  positionUpdated = false;

  @Output()
  positionChange = new EventEmitter<{ lat: string; lon: string }>();

  constructor(private errorService: ErrorService) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.initMap();
    });
  }

  private async initMap(): Promise<void> {
    //@ts-ignore
    const { Map } = await google.maps.importLibrary('maps');

    this.map = new Map(document.getElementById('map') as HTMLElement, {
      center: { lat: 39.83159406133986, lng: -76.9574386826966 },
      zoom: 8,
      innerWidth: 300,
      innerHeight: 300,
      label: {
        fontSize: '12px',
      },
      disableDefaultUI: true,
    });

    const dragHandler = (event: any) => {
      this.positionUpdated = true;
    };

    this.map.addListener('dragend', dragHandler);
  }

  async updatePos() {
    //@ts-ignore
    const { Marker } = await google.maps.importLibrary('marker');
    const map = this.map;
    const pos = this.map.getCenter();

    if (pos) {
      this.updateMap(pos);
      this.addressMarker.setPosition(pos);
      const coords = {
        lat: pos?.lat().toString(),
        lon: pos?.lng().toString(),
      };
      this.positionChange.emit(coords);
    }
  }

  updateMap(pos: google.maps.LatLngLiteral | google.maps.LatLng) {
    setTimeout(() => {
      this.map.setCenter(pos);
      this.map.setZoom(9);
      if (!this.addressMarker) {
        this.addressMarker = new google.maps.Marker({
          position: pos,
          map: this.map,
        });
      } else {
        this.addressMarker.setPosition(pos);
      }
    });
  }

  setLocationByCoords(lat: number, lon: number) {
    this.updateMap({
      lat: lat,
      lng: lon,
    });
  }

  setLocationByAddress(address: string) {
    const geocoder = new google.maps.Geocoder();
    const errorService = this.errorService;

    const geocodeResponse = (results: any, status: string) => {
      if (status == 'OK' && results) {
        this.updateMap(results[0].geometry.location);
      } else {
        if (status !== 'ZERO_RESULTS') {
          errorService.log('Geocode failure' + status.toString());
        }
      }
    };

    geocoder.geocode({ address: address }, geocodeResponse);
  }

  setLocation(place: google.maps.places.PlaceResult) {
    const location = place.geometry?.location;
    if (location) {
      this.updateMap(location);
    }
  }
}
