import { QueryParamsTestValueTypeFull } from 'src/app/core/queries/component-test/query-test-value-type-full.constant';

const QPTestBaseProps = `
  id 
  testType {
    id
    name
  }
  valueType
`;

const QPTestsList = `tests {
    id
    inUse
    valueType
    alphanumeric
    listOrder
    binary {
      color
      rangeText
    }
    constant
    list {
      color
      rangeText
    }
    checkbox
    full {
      allOperatorLogUsers
      allServiceRepUsers
      highAlarm {
        limit
        message
      }
      highControl {
        limit
        message
      }
      lowAlarm {
        limit
        message
      }
      lowControl {
        limit
        message
      }
      veryHighAlarm {
        limit
        message
      }
      veryLowAlarm {
        limit
        message
      }
    }
    maximum {
      allOperatorLogUsers
      allServiceRepUsers
      highAlarm {
        limit
        message
      }
      highControl {
        limit
        message
      }
      veryHighAlarm {
        limit
        message
      }
    }
    minimum {
      allOperatorLogUsers
      allServiceRepUsers
      lowAlarm {
        limit
        message
      }
      lowControl {
        limit
        message
      }
      veryLowAlarm {
        limit
        message
      }
    }
    calculation {
      id
      isLocked
      representation
      variables {
        description
        name
        inventoryId
        testId
      }
    }
    recordOnly {
      color
      rangeText
    }
    testType {
      id
      name
      shortName
    }
}`;

const QueryParamsTestValueTypeMaximum = `
maximum {
  veryHighAlarm {
    message
    limit
  }
  highAlarm {
    message
    limit
  }
  highControl {
    message
    limit
  }
  allOperatorLogUsers
  allServiceRepUsers
}
`;

const QueryParamsTestValueTypeMinimum = `
minimum {
  veryLowAlarm {
    message
    limit
  }
  lowAlarm {
    message
    limit
  }
  lowControl {
    message
    limit
  }
  allOperatorLogUsers
  allServiceRepUsers
}
`;

const QueryParamsTestCalculation = `
calculation {
  id
  representation
  variables {
    name
    description
    inventoryId
    testId
  }
  isLocked
}
`;

const QueryParamsTestType = `
testType {
  id
  name
  shortName
  active
  isCalculation
  inUse
  isWater
  isDeposit
}
`;

const QueryParamsTestTypeWithCalc = `
testType {
  id
  name
  shortName
  active
  isCalculation
  inUse
  isWater
  isDeposit
  calculation {
    id
    isLocked
    representation
    variables {
      name
      description
      testId
      inventoryId
    }
  }
}
`;

const QPTestFull = `
test {
  id
  valueType
  ${QueryParamsTestType},
  ${QueryParamsTestValueTypeFull},
  ${QueryParamsTestValueTypeMinimum},
  ${QueryParamsTestValueTypeMaximum},
  recordOnly {
    rangeText
    color
  }
  alphanumeric
  binary {
    color
    rangeText
  }
  list {
    rangeText
    color
  }
  checkbox
  constant
  calibration {
    minNum
    minDays
    maxNum
    maxDays
  }
  listOrder
  ${QueryParamsTestCalculation},
  isCalculation
  legacyRange
  inUse
  component {
    id
    longName
    shortName
    active
    testSortOrder
  }
}
`;

const QPTestListLite = `tests {
    id
    inUse
    valueType
    testType {
      id
      name
      shortName
    }
}`;

const GetTestsByComponentId = `query TestsByComponentId($componentId: Int!) {
  testsByComponentId(componentId: $componentId) {
    ... on Test {
      id
      testType {
        id
        name
      }
      valueType
      minimum {
        veryLowAlarm {
          limit
        }
        lowAlarm {
          limit
        }
        lowControl {
          limit
        }
      }
      maximum {
        highAlarm {
          limit
        }
        highControl {
          limit
        }
        veryHighAlarm {
          limit
        }
      }
      full {
        highAlarm {
          limit
        }
        highControl {
          limit
        }
        lowAlarm {
          limit
        }
        lowControl {
          limit
        }
        veryHighAlarm {
          limit
        }
        veryLowAlarm {
          limit
        }
      }
      recordOnly {
        color
        rangeText
      }
      valueType
    }
  }
}`;

export {
  QPTestsList,
  QPTestListLite,
  GetTestsByComponentId,
  QPTestBaseProps,
  QPTestFull,
  QueryParamsTestValueTypeMaximum,
  QueryParamsTestValueTypeMinimum,
  QueryParamsTestCalculation,
  QueryParamsTestType,
  QueryParamsTestTypeWithCalc,
};
