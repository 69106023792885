import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { map, Observable } from 'rxjs';
import { AuthStatus } from 'src/app/core/auth/auth-status/auth-status.model';
import { AuthService } from 'src/app/core/auth/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.authService.status$.pipe(
      map((status: AuthStatus) => {
        let res: UrlTree | boolean;

        if (status.hasSession && status?.user?.passwordResetRequired) {
          res = this.router.createUrlTree(['password-reset-required']);
        } else if (status.hasSession && !status?.user?.passwordResetRequired) {
          res = true;
        } else {
          res = this.router.createUrlTree(['login']);
        }

        return res;
      })
    );
  }
}
