<mat-card class="small-card pad-top login">
  <h2>{{ 'titles.login' | translate }}</h2>

  <form [formGroup]="loginForm" (ngSubmit)="login()" class="login-form">
    <p>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'labels.email' | translate }}</mat-label>
        <input autocomplete="on" matInput formControlName="email" />
      </mat-form-field>
    </p>
    <p>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'labels.password' | translate }}</mat-label>
        <input autocomplete="on" matInput formControlName="password" minlength="5" type="password" />
      </mat-form-field>
    </p>
    <app-error-list></app-error-list>

    <div class="text-center">
      <a href [routerLink]="'/password-reset'">{{ 'actions.forgot-password' | translate }}</a>
    </div>

    <div class="text-right">
      <button
        class="login-btn"
        type="submit"
        mat-raised-button
        [disabled]="!loginForm.valid || buttonDisable"
        color="primary"
      >
        Login
      </button>
    </div>
  </form>
</mat-card>
