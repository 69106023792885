export const TextEditorConfig = {
  ADMIN: {
    toolbar: [
      [
        'Bold',
        'Italic',
        'Underline',
        'Strike',
        '-',
        'Subscript',
        'Superscript',
      ],
      [
        'Format',
        '-',
        'RemoveFormat',
        '-',
        'NumberedList',
        'BulletedList',
        '-',
        'Indent',
        'Outdent',
      ],
      [
        'JustifyLeft',
        'JustifyCenter',
        'JustifyRight',
        'JustifyBlock',
      ],
      ['TextColor', 'BGColor'],
      [
        'Cut',
        'Copy',
        'Paste',
        'PasteFromWord',
        'PasteText',
        '-',
        'Undo',
        'Redo',
      ],
      [
        'Find',
        'Replace',
        '-',
        'SelectAll',
      ],
      [
        'Image',
        'Table',
        'HorizontalRule',
        'Youtube',
      ],
      ['Link', 'Unlink'],
    ],
    extraPlugins: 'youtube',
    youtube_width: '640',
    youtube_height: '360',
    youtube_responsive: true,
    youtube_related: false,
    youtube_privacy: true,
    youtube_autoplay: false,
    youtube_controls: true,
    youtube_disabled_fields: ['txtEmbed', 'chkAutoplay'], //https://ckeditor.com/cke4/addon/youtube
    disableAutoInline: true,
    contentsLangDirection: 'ltr',
    resize_enabled: true,
    removePlugins: ['exportpdf', 'autogrow'],
  },
  FULL: {
    toolbar: [
      [
        'Bold',
        'Italic',
        'Underline',
        'Strike',
        'Subscript',
        'Superscript',
      ],
      ['Format'],
      ['CopyFormatting', 'RemoveFormat'],
      ['SpecialChar'],
      ['NumberedList', 'BulletedList'],
      ['Indent', 'Outdent'],
      [
        'JustifyLeft',
        'JustifyCenter',
        'JustifyRight',
        'JustifyBlock',
      ],
      ['TextColor', 'BGColor'],
      '/',
      [
        'Cut',
        'Copy',
        'Paste',
        'PasteText',
        'PasteFromWord',
      ],
      ['Undo', 'Redo'],
      ['Find', 'Replace'],
      ['SelectAll'],
      ['Table', 'HorizontalRule'],
      ['Link'],
    ],
    disableAutoInline: true,
    contentsLangDirection: 'ltr',
    resize_enabled: true,
    removePlugins: ['exportpdf', 'autogrow'],
  },
  REPORT: {
    toolbar: [
      [
        'Bold',
        'Italic',
        'Underline',
        'Strike',
        'Subscript',
        'Superscript',
      ],
      ['CopyFormatting', 'RemoveFormat'],
      ['NumberedList', 'BulletedList'],
      ['Indent', 'Outdent'],
      [
        'JustifyLeft',
        'JustifyCenter',
        'JustifyRight',
        'JustifyBlock',
      ],
      ['TextColor', 'BGColor'],
      '/',
      [
        'Cut',
        'Copy',
        'Paste',
        'PasteText',
        'PasteFromWord',
      ],
      ['Undo', 'Redo'],
      ['Find', 'Replace'],
      ['SelectAll'],
      ['Table', 'HorizontalRule'],
      ['Link'],
      ['Image'],
    ],
    disableAutoInline: true,
    contentsLangDirection: 'ltr',
    resize_enabled: false,
    removePlugins: ['exportpdf', 'autogrow'],
  },
  ALERT: {
    toolbar: [
      [
        'Bold',
        'Italic',
        'Underline',
        'Subscript',
        'Superscript',
      ],
      ['Link'],
    ],
    disableAutoInline: true,
    contentsLangDirection: 'ltr',
    resize_enabled: false,
    removePlugins: ['exportpdf', 'autogrow'],
  },
  BASIC: {
    toolbar: [
      [
        'Bold',
        'Italic',
        'Underline',
        'Strike',
      ],
      ['Undo', 'Redo'],
      ['SelectAll'],
    ],
    disableAutoInline: true,
    contentsLangDirection: 'ltr',
    resize_enabled: false,
    removePlugins: ['exportpdf', 'autogrow'],
  },
};
