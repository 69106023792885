<div class="row">
    <div class="top-bar col-12 d-flex flex-column flex-md-row align-items-center  box-shadow">
         
        
        <!-- App Logo -->
        <div class="logo-container mr-md-auto">
          <img
            *ngIf="logoPath"
            [src]="logoPath"
            class="logo"
            alt="Company Logo"
          />
        </div>
        
            <!--fa-icon class="fa-sharp" (click)="openDashboard()"  [icon]="dashboardIcon" matTooltip="{{ 'View Dashboard' | translate }}"></fa-icon-->
            <!--div class="system mr-md-auto " *ngIf="(system$ | async) as system">
                
                <span class="d-inline-block ml-3 mr-3">System: {{system.name}}</span>
                <fa-icon class="fa-sharp"  [icon]="resetIcon"></fa-icon>
            </div-->
        

        <!-- Notifications Menu -->
        <app-notify-menu  *ngIf="!!(authStatus$ | async)?.user"></app-notify-menu>
        
        <!-- User Menu -->
        <app-user-menu *ngIf="(authStatus$ | async)?.user"></app-user-menu>
        
        
    </div>
</div>
