import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { EntityOption } from 'src/app/components/entity-select/models/entity-option.model';
import { GraphqlService } from '../graphql/graphql.service';

@Injectable({
  providedIn: 'root',
})
export class FavoritesService {
  userFavorites: EntityOption[] = [];

  constructor(private graphService: GraphqlService) {}

  getFavorites() {
    return lastValueFrom(
      this.graphService.query({
        query: `query SystemFavorite {
        systemFavorite {
          ... on SystemFavorites {
            id
            systems {
              id
              name
            }
          }
        }
      }`,
      })
    ).then((res: any) => {
      if (res.data.systemFavorite) {
        this.userFavorites = res.data.systemFavorite.systems;
        return res.data.systemFavorite.systems;
      }
    });
  }
}
