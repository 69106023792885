import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom, map, Observable } from 'rxjs';
import { AppConfig } from '../models/app-config.model';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  configSubject = new BehaviorSubject<AppConfig>({} as AppConfig);
  config$ = this.configSubject.asObservable();

  constructor(private http: HttpClient) {}

  getConfig(systemId: number): Promise<AppConfig> {
    const res = this.http.get(`/api/configs/${systemId}`).pipe(
      map((val) => {
        const config = val as AppConfig;
        this.configSubject.next(config);
        return config;
      })
    );

    return lastValueFrom(res);
  }
}
