import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { ChildActivationEnd } from '@angular/router';
import { Observable } from 'rxjs';
import { BreadcrumbNode } from 'src/app/components/breadcrumbs/breadcrumb-node.model';
import { AppResponse } from 'src/app/core/models/app-response/app-response.model';
import { DashboardService } from 'src/app/features/dashboards/services/dashboard.service';

@Component({
  selector: 'app-list-dashboards',
  templateUrl: './list-dashboards.component.html',
  styleUrls: ['./list-dashboards.component.scss'],
})
export class ListDashboardsComponent implements OnInit {
  dashboards$: Observable<AppResponse>;

  @Input()
  miniMode = false;

  dashboards = [];

  breadcrumbs: BreadcrumbNode[] = [
    {
      label: 'titles.my-dashboards',
    },
  ];

  constructor(private dashboardService: DashboardService) {
    //this.dashboards$ = this.dashboardService.getDashboards();
  }

  ngOnInit(): void {}
}
