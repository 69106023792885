<div class="entity-list" [ngClass]="disabled ? 'disabled' : ''">
  <!-- Select -->
  <mat-form-field appearance="fill">
    <mat-label>{{ selectLabel | translate }}</mat-label>
    <mat-select
      panelClass="entity-list-select-panel"
      disableOptionCentering
      [compareWith]="selectCompare"
      [tabindex]="0"
      [(ngModel)]="selected"
      (selectionChange)="doSelect()"
      (openedChange)="onSelectToggle($event)"
      [disabled]="disabled"
    >
      <div [ngClass]="{ selected: isSelected }">
        <!-- Dropdown Search-->
        <div class="dropdown-search">
          <mat-form-field class="w-100">
            <input
              matInput
              type="text"
              [formControl]="searchControl"
              tabindex="0"
              placeholder="Search"
            />
            <mat-icon matPrefix>search</mat-icon>
          </mat-form-field>
        </div>

        <!-- Dropdown list-->
        <div class="option-group d-flex justify-content-around">
          <mat-option
            class="menu-btn w-100"
            *ngIf="_createPerm === 'YES'"
            [value]="'ADD'"
            (onSelectionChange)="createSelect($event)"
            ><mat-icon>add_circle</mat-icon
            ><span>{{ 'actions.add' | translate }}</span></mat-option
          >
          <mat-option
            class="menu-btn w-100"
            *ngIf="_accessPerm === 'EDIT' && !!selected"
            [value]="'EDIT'"
            (onSelectionChange)="modifySelect($event)"
            ><mat-icon>edit</mat-icon>{{ 'actions.edit' | translate }}
          </mat-option>
          <mat-option
            class="menu-btn w-100"
            *ngIf="_accessPerm === 'EDIT' && !!selected"
            [value]="'DELETE'"
            (click)="delete(); $event.preventDefault(); $event.stopPropagation()"
            ><mat-icon>delete</mat-icon>{{ 'actions.delete' | translate }}</mat-option
          >
        </div>
        <mat-option
          class="menu-btn"
          *ngIf="!(filteredOptions$ | async)?.length"
        ></mat-option>
        <div class="scroll-view">
          <mat-option
            *ngFor="let item of filteredOptions$ | async; let i = index"
            [value]="item"
          >
            <span *ngIf="showItemId">{{ ' #' + item.id + ' - ' }}</span>
            <span>{{ item.name }}</span>
            <button
              class="entity-button"
              matTooltip="View"
              (click)="openDashboard($event); $event.stopPropagation()"
              *ngIf="selected?.id === item.id"
              mat-icon-button
            >
              <i class="fa fa-duotone fa-arrow-right-from-bracket fa-lg"></i>
            </button>
          </mat-option>
        </div>
      </div>
    </mat-select>
    <mat-icon
      *ngIf="isSelected"
      tabindex="0"
      class="clear-btn"
      mat-icon-button
      (keyup.enter)="close(); $event.stopPropagation()"
      (click)="close(); $event.stopPropagation()"
      >close</mat-icon
    >
  </mat-form-field>
</div>
