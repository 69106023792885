import { Component, ContentChild, ElementRef, EventEmitter, Output } from '@angular/core';
import { filter, fromEvent, Subject, switchMap, take } from 'rxjs';
import { EditModeDirective } from '../edit-mode/edit-mode.directive';
import { ViewModeDirective } from '../view-mode/view-mode.directive';

@Component({
  selector: 'editable',
  template: ` <ng-container *ngTemplateOutlet="currentView"></ng-container> `,
})
export class EditableComponent {
  @Output() update = new EventEmitter();
  @ContentChild(ViewModeDirective) viewModeTpl: ViewModeDirective;
  @ContentChild(EditModeDirective) editModeTpl: EditModeDirective;

  mode: 'view' | 'edit' = 'view';

  editMode = new Subject();
  editMode$ = this.editMode.asObservable();

  constructor(private host: ElementRef) {}

  get currentView() {
    return this.mode === 'view' ? this.viewModeTpl.tpl : this.editModeTpl.tpl;
  }

  ngOnInit() {
    this.viewModeHandler();
    this.editModeHandler();
  }

  private get element() {
    return this.host.nativeElement;
  }

  private viewModeHandler() {
    fromEvent(this.element, 'dblclick')
      .pipe()
      .subscribe(() => {
        this.mode = 'edit';
        this.editMode.next(true);
      });
  }

  private editModeHandler() {
    const clickOutside$ = fromEvent(document, 'click').pipe(
      filter(({ target }) => this.element.contains(target) === false),
      take(1)
    );

    this.editMode$.pipe(switchMap(() => clickOutside$)).subscribe((event) => {
      this.update.next('');
      this.mode = 'view';
    });
  }
}
