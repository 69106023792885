import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ViewDashboardRoutingModule } from './view-dashboard-routing.module';
import { ViewDashboardComponent } from './view-dashboard.component';
import { ComponentsModule } from 'src/app/components/components.module';
import { TrendsGraphModule } from 'src/app/graphs/trends-graph/trends-graph.module';
import { InventoryGraphModule } from 'src/app/graphs/inventory-graph/inventory-graph.module';
import { GraphsModule } from 'src/app/graphs/graphs.module';
import { FusionChartsModule } from 'angular-fusioncharts';
import FusionCharts from 'fusioncharts';
import Charts from 'fusioncharts/fusioncharts.charts';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';

FusionChartsModule.fcRoot(FusionCharts, Charts, FusionTheme);

@NgModule({
  declarations: [ViewDashboardComponent],
  imports: [
    CommonModule,
    ComponentsModule,
    ViewDashboardRoutingModule,
    FusionChartsModule,
    TrendsGraphModule,
    InventoryGraphModule,
  ],
  exports: [ViewDashboardComponent],
})
export class ViewDashboardModule {
  constructor() {}
}
