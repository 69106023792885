<div class="system-nav">
    <div  *ngIf="mode === SystemNavModes.Input">
        <form class="input-box d-flex" [formGroup]="form" (ngSubmit)="onSubmit()">
            <mat-form-field appearance="outline">
                <mat-label>{{ 'System Id' | translate }}</mat-label>
                <input autocomplete="on" (click)="errorState = false" matInput type="text" formControlName="systemInput" />
            </mat-form-field>
            <div class="ml-3 mt-3">
                <button mat-flat-button color="primary" [disabled]="!form.valid" type="submit" >Go</button>
            </div>
        </form>
        <div class="history pod mb-2" *ngIf="systemHistory.length">
            <mat-label class="mb-3">Recently Viewed:</mat-label>
            <div class="d-flex flex-row-reverse justify-content-around">
                <button class="ml-2 system-button" mat-button *ngFor="let item of systemHistory" (click)="navigateToSystem(item.id)">#{{ item.id }} - {{ item.name }}</button>
            </div>
            
        </div>
        <div class="alert alert-danger" *ngIf="errorState">
            <span>This system cannot be loaded. Please try another ID.</span>
        </div>
    </div>
    <div class="" *ngIf="mode === SystemNavModes.FavoriteSelect">
        <mat-form-field>
            <mat-label>{{ 'Select Favorited System' | translate }}:</mat-label>
            <mat-select required (selectionChange)="update($event)" placeholder="None">
                <mat-option  *ngFor="let item of favorites"  [value]="item">{{ item.name |
                    snakeToTitleCase | titlecase}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>