import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { AppService } from 'src/app/core/services/app/app.service';
import { AuthService } from 'src/app/core/auth/auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { ErrorService } from 'src/app/core/services/error/error.service';
import { GraphqlService } from 'src/app/core/services/graphql/graphql.service';
import { SettingsService } from 'src/app/features/app-settings/services/settings.service';
import { AccountService } from '../account/services/account-service/account.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup = new FormGroup({
    email: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required),
  });

  httpClient: HttpClient;

  buttonDisable = false;

  constructor(
    private appService: AppService,
    private authService: AuthService,
    private errorService: ErrorService,
    private accountService: AccountService
  ) {}

  ngOnInit(): void {
    this.appService.sidenavToggle.emit(false);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.loginForm.markAsDirty();
    }, 300);
  }

  login() {
    const vars = this.loginForm.getRawValue();

    this.errorService.clear();
    this.buttonDisable = true;
    this.authService
      .getLogin(vars)
      .then(() => {
        this.buttonDisable = false;
      })
      .catch((e) => {
        this.errorService.log(e);
      });
  }
}
