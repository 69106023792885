<mat-dialog-content class="dialog-content">
  <p>
    {{ message }}
  </p>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-stroked-button mat-dialog-close>{{ cancelButtonText }}</button>
  <button mat-flat-button color="primary" (click)="onConfirmClick()">
    {{ confirmButtonText }}
  </button>
</mat-dialog-actions>
