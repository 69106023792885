import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TextEditorConfig } from './text-editor.config';
import { AppService } from '../../core/services/app/app.service';

@Component({
  selector: 'app-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.scss'],
})
export class TextEditorComponent implements OnInit {
  _content = '';
  preloadContent = '';

  _context: any; // Any object, when multiple editors need a parent object id

  _editorConfig = TextEditorConfig.FULL;

  @Output() contentChanged = new EventEmitter<string>();
  @Output() contentChange = new EventEmitter<string>();

  @Output() blur = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {}

  @Input() set content(val: string) {
    this._content = val;
    // this.onContentChanged({ editor: { getData: () => val } });
    // WHY ... this be looping ?
  }

  get content() {
    return this._content;
  }

  @Input() set context(val: any) {
    this._context = val;
  }

  get context() {
    return this._context;
  }

  @Input() set configuration(val: any) {
    if (val) {
      this._editorConfig = val;
    }
  }

  onContentChanged(event: any): void {
    const value = event.editor.getData();
    this.contentChange.emit(value);
  }

  onBlur(event: any): void {
    const value = event.editor.getData();
    this.blur.emit(value);
  }
}
