import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { Router } from '@angular/router';
import {
  faCircleChevronLeft,
  faCircleChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { AuthStatus } from 'src/app/core/auth/auth-status/auth-status.model';
import { AuthService } from 'src/app/core/auth/auth/auth.service';
import { AppActions } from 'src/app/core/constants/app-actions/app-actions.constant';
import { NavItem } from 'src/app/core/models/nav-item/nav-item.model';
import { AppService } from 'src/app/core/services/app/app.service';
import { UserType } from 'src/app/feature-modules/users/models/user.model';

export const subNavInitialState = Object.freeze({
  reports: [
    AppActions.reports.ServiceReport,
    AppActions.reports.VisitReport,
    AppActions.reports.OperatorLogs,
    AppActions.reports.LabReport,
  ],
  tools: [
    AppActions.tools.AttachmentManager,
    AppActions.tools.CustomerProductsLocator,
    AppActions.tools.NotificationCenter,
  ],
  graphs: [
    AppActions.graphs.TrendsReport,
    //AppActions.graphs.SystemHistory,
    AppActions.graphs.OpenDraftReport,
    AppActions.graphs.ComponentReview,
    //AppActions.graphs.TimeManagement,
    //AppActions.graphs.InventorySummary,
    //AppActions.graphs.MasterControllerReport,
    //AppActions.graphs.ReportScheduler,
    AppActions.graphs.CustomerReviewReport,
    //AppActions.graphs.CustomerCompliance,
  ],
  setup: [
    AppActions.reports.ReportsSetup,
    AppActions.tools.SystemComponents,
    AppActions.tools.ManageChecklists,
    AppActions.tools.QuestionnaireList,
    AppActions.tools.CouponTypes,
  ],
  admin: [
    AppActions.tools.ApplicationSettings,
    AppActions.tools.InternationalSettings,
  ],
  users: [
    AppActions.users.ManageUsers,
    AppActions.users.UserPermissions,
  ],
});

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit {
  navItems: any[] = [];

  subNavItems: any = {};

  treeControl = new NestedTreeControl((node: any) => node.items);
  treeSource = new MatTreeNestedDataSource();

  subMenuData: NavItem[] = [];

  activeItem: NavItem | null;

  menuIcon = faCircleChevronLeft;
  menuIconCollapsed = faCircleChevronRight;

  isCollapsed = false;
  isExpanded = false;

  @Output() onMenuToggle = new EventEmitter();
  @Output() onMenuExpand = new EventEmitter();

  subs: Subscription[] = [];

  constructor(
    private router: Router,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.initialState();
    this.watchLogin();
    this.watchLogout();
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  initialState() {
    this.treeSource.data = this.navItems;
    this.subNavItems = AppService.deepCopy(subNavInitialState);
  }

  watchLogin() {
    const sub1 = this.authService.onLogin.subscribe((status: AuthStatus) => {
      this.setNavItems();
      if (status.hasSession) {
        this.checkAccess();
      }
    });
    this.subs.push(sub1);
  }

  watchLogout() {
    const sub2 = this.authService.onLogout.subscribe(() => {
      this.initialState();
    });

    this.subs.push(sub2);
  }

  setNavItems() {
    this.navItems = [
      AppActions.other.Home,
      AppActions.reports.ReportsCreate,
      AppActions.reports.ReportsView,
      AppActions.other.SetupReports,
      AppActions.other.Graphs,
      AppActions.other.Tools,
    ];
  }

  checkAccess() {
    const permissions = this.authService.getPermissions();

    if (permissions && permissions.miscellaneous) {
      // View Order Request
      if (permissions.miscellaneous.orderRequestAccess !== 'NONE') {
        this.subNavItems.tools.splice(0, 0, AppActions.tools.OrderRequest);
      }

      // View Customer Contact
      if (permissions.miscellaneous.customerContactsAccess !== 'NONE') {
        this.subNavItems.tools.splice(1, 0, AppActions.tools.CustomerContacts);
      }

      // View Report Phrases
      if (permissions.miscellaneous.reportPhrasesAccess !== 'NONE') {
        this.subNavItems.tools.splice(7, 0, AppActions.tools.ReportPhrases);
      }

      // List Products
      if (permissions.miscellaneous.masterProductListAccess !== 'NONE') {
        this.subNavItems.tools.splice(3, 0, AppActions.tools.ListProducts);
      }
    }
    if (permissions && permissions.reports) {
      // List Invetories
      if (permissions.reports.inventoryReportAccess !== 'NONE') {
        this.subNavItems.setup.splice(2, 0, AppActions.tools.Inventory);
      }
    }

    if (permissions && permissions.administration) {
      // View UserManagement Item
      if (permissions.administration.adminCenter !== 'NO') {
        this.navItems.push(AppActions.other.UserManagement);
      }

      // View AdminCenter Item
      if (permissions.administration.adminCenter !== 'NO') {
        this.navItems.push(AppActions.other.AdminCenter);
      }

      // View Controller Item
      if (permissions.administration.controllerAccess !== 'NONE') {
        this.navItems.splice(4, 0, AppActions.reports.SetupControllers);
      }

      // Clone Customer
      if (permissions.administration.copyCustomerAccess !== 'NO') {
        this.subNavItems.tools.splice(5, 0, AppActions.tools.CloneCustomer);
      }

      // View TestTypes Item
      if (permissions.administration.testTypesAcccess !== 'NONE') {
        this.subNavItems.setup.splice(1, 0, AppActions.tools.TestTypes);
      }

      // View Questionaire Item
      if (
        !this.authService.checkRole([UserType.ACCOUNT_ADMIN, UserType.ALIQUOT_ADMIN]) &&
        this.authService.status.getValue().user?.permission.id !== 13
      ) {
        //'System Administrators'
        const index = this.subNavItems.setup.findIndex(
          (item: NavItem) => item === AppActions.tools.QuestionnaireList
        );
        if (index >= 0) {
          this.subNavItems.setup.splice(index, 1);
        }
      }

      // List System Types
      if (permissions.administration.systemTypesAccess !== 'NONE') {
        this.subNavItems.setup.splice(7, 0, AppActions.tools.SystemTypes);
      }
    }
  }

  collapse() {
    this.isExpanded = false;
    this.activeItem = null;
    this.onMenuToggle.emit(this.isCollapsed);
    this.onMenuExpand.emit(false);
  }

  toggle() {
    this.isCollapsed = !this.isCollapsed;
    this.onMenuToggle.emit(this.isCollapsed);
  }

  itemSelect(item: NavItem) {
    if (item == AppActions.other.SetupReports) {
      this.isExpanded = item == this.activeItem ? !this.isExpanded : true;
      this.subMenuData = this.subNavItems.setup;
    } else if (item == AppActions.other.Graphs) {
      this.isExpanded = item == this.activeItem ? !this.isExpanded : true;
      this.subMenuData = this.subNavItems.graphs;
    } else if (item == AppActions.other.Tools) {
      this.isExpanded = item == this.activeItem ? !this.isExpanded : true;
      this.subMenuData = this.subNavItems.tools;
    } else if (item == AppActions.other.AdminCenter) {
      this.isExpanded = item == this.activeItem ? !this.isExpanded : true;
      this.subMenuData = this.subNavItems.admin;
    } else if (item == AppActions.other.UserManagement) {
      this.isExpanded = item == this.activeItem ? !this.isExpanded : true;
      this.subMenuData = this.subNavItems.users;
    } else {
      this.isExpanded = false;
      this.router.navigate([item.path]);
    }
    if (this.activeItem === item) {
      this.activeItem = null;
    } else {
      this.activeItem = item;
    }

    this.onMenuExpand.emit(this.isExpanded);
  }

  hasChild = (node: { items?: any[] }) => !!(node.items && node.items.length > 0);
}
