import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom, map } from 'rxjs';
import { PhoneNumberPipe } from 'src/app/components/pipes/phoneNumber';
import {
  GraphqlService,
  GraphResponse,
} from 'src/app/core/services/graphql/graphql.service';
import { Client } from '../models/client.model';

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  clients: BehaviorSubject<Client[]> = new BehaviorSubject([] as Client[]);
  clients$ = this.clients.asObservable();
  clientsFetchTime: string;

  queries: {
    listClients: string;
    listClientsWithCustomers: string;
    listClientsWithEntities: string;
    getClient: string;
    getClientWithEntities: string;
  } = {
    listClients: `query ListClients($all: Boolean, $idList: [Int!]!) {
      clients(all: $all, idList: $idList) {
        ... on Client {
          id
          name
        }
        ... on NotFoundError {
          code
          message
        }
        ... on NotAllowed {
          message
          code
        }
        ... on InvalidParametersError {
          message
          code
        }
      }
    }`,
    listClientsWithCustomers: `query Clients($all: Boolean) {
      clients(all: $all) {
        ... on Client {
          id
          name
          customers {
            id
            name
            active
          }
        }
        ... on NotFoundError {
          code
          message
        }
        ... on NotAllowed {
          message
          code
        }
        ... on InvalidParametersError {
          message
          code
        }
      }
    }`,
    listClientsWithEntities: `query Clients($all: Boolean) {
      clients(all: $all) {
        ... on Client {
          id
          name
          customers {
            id
            name
            active
            facilities {
              id
              name
            }
          }
        }
        ... on NotFoundError {
          code
          message
        }
        ... on NotAllowed {
          message
          code
        }
        ... on InvalidParametersError {
          message
          code
        }
      }
    }`,
    getClient: `query Client($clientId: Int!) {
      client(id: $clientId) {
        ... on Client {
          id
          name
          address {
            city
            region
            street1
            street2
            street3
            street4
            unit
            zipCode
          }
          addressFormat
          defaultFromAddress
          doNotReply
          language
          lat
          lon
          legalStatement
          longDateFormat
          maxNumDrafts
          orderRequestDisclaimer
          paperSize
          passwordDomain
          supportEmail
          shortDateFormat
          timeFormat
          reportLogo {
            description
            file
            id
            locationId
            locationType
          }
          unitSystem
          contactInfo {
            fax
            faxExt
            phoneFormat
            phoneNumber
            phoneNumberExt
          }       
          timezone {
            id
            name
            offset
          }
          customers {
            id
            name
            active
          }
        }
      }
    }`,
    getClientWithEntities: `query ClientWithEntities($clientId: Int!) {
      client(id: $clientId) {
        ... on Client {
          id
          name
          customers {
            active
            id
            name
          }
        }
      }
    }`,
  };

  mutations: { createClient: string; modifyClient: string } = {
    createClient: `mutation CreateClient($file: FileInput, $data: CreateClientInput!, $addressData: AddressInput) {
      manageClient(file:  $file, data: $data, addressData: $addressData) {
        ... on Client {
          id
          name
        }
      }
    }`,
    modifyClient: `mutation ManageClient($id: Float, $file: FileInput, $data: CreateClientInput!, $addressData: AddressInput) {
      manageClient(id: $id, file: $file, data: $data, addressData: $addressData) {
        ... on Client {
          id
          name
        }
      }
    }`,
  };

  static getReportFooterText(client: Client): string {
    const numberPipe = new PhoneNumberPipe();
    let response = '';

    if (client && client.address && client.name) {
      response = `${client?.name} ${client?.address?.street1 || ''}
      ${client?.address?.street2 || ''} ${client?.address?.city || ''}
      ${client?.address?.country || ''} ${client?.address?.zipCode || ''} ${' - '}`;

      if (client.contactInfo && client.contactInfo.phoneNumber) {
        response += ` T: ${numberPipe.transform(+client.contactInfo.phoneNumber)}`;
      }
    }

    return response;
  }

  constructor(
    private http: HttpClient,
    private graphService: GraphqlService
  ) {}

  getClients(): Promise<Client[]> {
    return lastValueFrom(
      this.graphService
        .query<{ clients: Client[] }>({
          query: this.queries.listClients,
          variables: {
            all: true,
            idList: [],
          },
        })
        .pipe(
          map((res: GraphResponse<{ clients: Client[] }>) => {
            let ret: Client[] = [];
            if (res.data && res.data.clients) {
              const clients = res.data.clients;
              ret = clients;
            }
            return ret;
          })
        )
    );
  }

  getClientsWithCustomers(): Promise<Client[]> {
    return lastValueFrom(
      this.graphService
        .query<{ clients: Client[] }>({
          query: this.queries.listClientsWithCustomers,
          variables: {
            all: true,
            idList: [],
          },
        })
        .pipe(
          map((res: GraphResponse<{ clients: Client[] }>) => {
            let ret: Client[] = [];
            if (res.data && res.data.clients) {
              const clients = res.data.clients;
              ret = clients;
            }
            return ret;
          })
        )
    );
  }

  getClientsWithEntities(): Promise<Client[]> {
    return lastValueFrom(
      this.graphService
        .query<{ clients: Client[] }>({
          query: this.queries.listClientsWithEntities,
          variables: {
            all: true,
          },
        })
        .pipe(
          map((res: GraphResponse<{ clients: Client[] }>) => {
            let ret: Client[] = [];
            if (res.data && res.data.clients) {
              const clients = res.data.clients;
              ret = clients;
            }
            return ret;
          })
        )
    );
  }

  getClientWithEntities(id: number): Promise<Client> {
    return lastValueFrom(
      this.graphService
        .query<{ client: any }>({
          query: this.queries.getClientWithEntities,
          variables: {
            clientId: id,
          },
        })
        .pipe(
          map((res: GraphResponse<{ client: Client }>) => {
            let ret: any;
            if (res.data && res.data.client) {
              const client = res.data.client;
              ret = client;
            }
            return ret;
          })
        )
    );
  }

  getClient(id: number): Promise<Client> {
    return lastValueFrom(
      this.graphService
        .query<{ client: Client }>({
          query: this.queries.getClient,
          variables: {
            clientId: id,
          },
        })
        .pipe(
          map((res: GraphResponse<{ client: Client }>) => {
            let ret: any = null;
            if (res.data && res.data.client) {
              const client = res.data.client;
              ret = client;
            }
            return ret;
          })
        )
    );
  }

  delete(id: number): Promise<boolean> {
    return lastValueFrom(
      this.graphService
        .mutate<{ deleteClient: any }>({
          mutation: `mutation DeleteClient($deleteClientId: Float!) {
          deleteClient(id: $deleteClientId)
        }`,
          variables: {
            deleteClientId: id,
          },
        })
        .pipe(
          map((res: GraphResponse<{ deleteClient: any }>) => {
            let ret = false;
            if (res.errors) {
              throw res.errors;
            }
            if (res.data && res.data.deleteClient) {
              ret = true;
            }
            return ret;
          })
        )
    );
  }
}
