<div class="d-flex side-wrap" [ngClass]="[isExpanded ? 'expanded' : '', isCollapsed ? 'collapsed' : '']">
    <div class="menu">
        <button *ngFor="let item of navItems" [ngClass]="{'active' : activeItem === item}" mat-button (click)="itemSelect(item)">
            
            <i class="fa fa-sharp" [ngClass]="item.icon"></i>
            <span class="label">{{item.label | translate }}</span>
            <span class="indicator"></span>
        </button>
    </div>
    <div class="menu sub" >
        <button *ngFor="let subItem of subMenuData" mat-button  (click)="collapse()" [routerLink]="subItem.path">
            <i class="fa fa-sharp" [ngClass]="subItem.icon"></i>
            <span class="label">{{subItem.label | translate }}</span>
            <span class="indicator"></span>
        </button>
    </div>
    
</div>

<div class="collapse-toggle" [ngClass]="isCollapsed ? 'rotate' : ''">
    <p (click)="toggle(); $event.stopPropagation()">
        <fa-icon [icon]="menuIcon"></fa-icon>
    </p>
</div>