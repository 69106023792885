import { Pipe, PipeTransform } from '@angular/core';
import { TestTypesData } from 'src/app/data/test-types.data';

@Pipe({
  name: 'snakeToTitleCase',
})
export class SnakeToTitleCasePipe implements PipeTransform {
  transform(value: string, ...args: any[]) {
    return (value && value.split('_').join(' ')) || '';
  }
}
