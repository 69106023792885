import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom, map } from 'rxjs';
import {
  GraphqlService,
  GraphResponse,
} from 'src/app/core/services/graphql/graphql.service';
import { Customer } from 'src/app/feature-modules/customers/models/customer.model';
import { User } from '../../users/models/user.model';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  customers: BehaviorSubject<Customer[]> = new BehaviorSubject([] as Customer[]);
  customers$ = this.customers.asObservable();

  queries: {
    getCustomers: string;
    getCustomer: string;
    getCustomerAndFacilities: string;
    getCustomerWithEntities: string;
  } = {
    getCustomers: `query Customers($idList: [Int!]!) {
      customers(idList: $idList) {
        ... on Customer {
          id
          name
        }
        ... on NotFoundError {
          message
          code
        }
        ... on NotAllowed {
          message
          code
        }
        ... on InvalidParametersError {
          message
          code
        }
        ... on ConflictError {
          message
          code
        }
      }
    }`,
    getCustomer: `query Customer($customerId: Int!) {
      customer(id: $customerId) {
        ... on Customer {
          active
          accountNumber
          address {
            city
            id
            region
            street1
            street2
            street3
            street4
            unit
            zipCode
            country
          }
          addressFormat
          client {
            id
            name
            address {
              id
              region
              street1
              street2
              country
              city
              zipCode
            }
            timezone {
              id
            }
            legalStatement
            contactInfo {
              phoneFormat
              phoneNumber
            }
          }
          contactInfo {
            fax
            faxExt
            phoneFormat
            phoneNumber
            phoneNumberExt
          }
          id
          lat
          lon
          name
          prospect
          showCountryOnRep
          tag1
          tag2
          tag3
          timezone {
            id
            name
            offset
            abbreviation
          }
        }
      }
    }`,
    getCustomerAndFacilities: `query Customer($customerId: Int!) {
      customer(id: $customerId) {
        ... on Customer {
          active
          accountNumber
          address {
            city
            id
            region
            street1
            street2
            street3
            street4
            unit
            zipCode
          }
          addressFormat
          facilities {
            id
            name
          }
          contactInfo {
            fax
            faxExt
            phoneFormat
            phoneNumber
            phoneNumberExt
          }
          id
          name
          timezone {
            id
            name
            offset
            abbreviation
          }
        }
      }
    }`,
    getCustomerWithEntities: `query Customer($customerId: Int!) {
      customer(id: $customerId) {
        ... on Customer {
          id
          name
          client {
            id
            name
          }
          facilities {
            id
            name
            buildings {
              id
              name
            }
          }
        }
        ... on NotFoundError {
          message
          code
        }
        ... on NotAllowed {
          message
          code
        }
        ... on InvalidParametersError {
          message
          code
        }
        ... on ConflictError {
          message
          code
        }
      }
    }`,
  };

  mutations: any = {
    createCustomer: `mutation CreateCustomer($data: CustomerInput!, $addressData: AddressInput) {
      manageCustomer(data: $data, addressData: $addressData) {
        ... on Customer {
          id
          name
        }
        ... on NotFoundError {
          code
          message
        }
        ... on NotAllowed {
          code
          message
        }
        ... on InvalidParametersError {
          code
          message
        }
        ... on ConflictError {
          message
          code
        }
      }
    }`,
    manageCustomer: `mutation ManageCustomer($data: CustomerInput!, $addressData: AddressInput, $manageCustomerId: Float) {
      manageCustomer(data: $data, addressData: $addressData, id: $manageCustomerId) {
        ... on Customer {
          id
          name
        }
        ... on NotFoundError {
          code
          message
        }
        ... on NotAllowed {
          code
          message
        }
        ... on InvalidParametersError {
          code
          message
        }
      }
    }`,
  };

  constructor(
    private http: HttpClient,
    private graphService: GraphqlService
  ) {}

  getCustomers(idList: number[]) {
    return lastValueFrom(
      this.graphService
        .query<{ customers: Customer[] }>({
          query: this.queries.getCustomers,
          variables: {
            idList: idList,
          },
        })
        .pipe(
          map((res: GraphResponse<{ customers: Customer[] }>) => {
            let ret: any;
            if (res.data && res.data.customers) {
              ret = res.data.customers;
            }
            return ret;
          })
        )
    );
  }

  getCustomerWithFacilities(id: number): Promise<Customer> {
    return lastValueFrom(
      this.graphService
        .query<{ customer: any }>({
          query: this.queries.getCustomerAndFacilities,
          variables: {
            customerId: id,
          },
        })
        .pipe(
          map((res: GraphResponse<{ customer: Customer }>) => {
            let ret: any = {};
            if (res.errors) {
              throw res.errors;
            }
            if (res.data && res.data.customer) {
              ret = res.data.customer;
            }
            return ret;
          })
        )
    );
  }

  getCustomer(id: number): Promise<Customer> {
    return lastValueFrom(
      this.graphService
        .query<{ customer: any }>({
          query: this.queries.getCustomer,
          variables: {
            customerId: id,
          },
        })
        .pipe(
          map((res: GraphResponse<{ customer: Customer }>) => {
            let ret: any = {};
            if (res.errors) {
              throw res.errors;
            }
            if (res.data && res.data.customer) {
              ret = res.data.customer;
            }
            return ret;
          })
        )
    );
  }

  getCustomerWithEntities(id: number): Promise<Customer> {
    return lastValueFrom(
      this.graphService
        .query<{ customer: any }>({
          query: this.queries.getCustomerWithEntities,
          variables: {
            customerId: id,
          },
        })
        .pipe(
          map((res: GraphResponse<{ customer: Customer }>) => {
            let ret: any;
            if (res.data && res.data.customer) {
              const customer = res.data.customer;
              ret = customer;
            }
            return ret;
          })
        )
    );
  }

  getCustomerAccess(id: number): Promise<User[]> {
    return lastValueFrom(
      this.graphService
        .query<any>({
          query: `query Query($customerId: Float!) {
          customerAccess(customerId: $customerId) {
            ... on User {
              id
              firstName
              lastName
            }
          }
        }`,
          variables: {
            customerId: id,
          },
        })
        .pipe(
          map((res: GraphResponse<{ customerAccess: User[] }>) => {
            let ret: any;
            if (res.data && res.data.customerAccess) {
              ret = res.data.customerAccess;
            }
            return ret;
          })
        )
    );
  }

  delete(id: number): Promise<boolean> {
    return lastValueFrom(
      this.graphService
        .mutate<{ deleteCustomer: any }>({
          mutation: `mutation DeleteCustomer($deleteCustomerId: Float!) {
          deleteCustomer(id: $deleteCustomerId)
        }`,
          variables: {
            deleteCustomerId: id,
          },
        })
        .pipe(
          map((res: GraphResponse<{ deleteCustomer: any }>) => {
            let ret = false;
            if (res.errors) {
              throw res.errors;
            }
            if (res.data && res.data.deleteCustomer) {
              ret = true;
            }
            return ret;
          })
        )
    );
  }
}
