<div class="pdfUploadContainer" appDragAndDrop (filesDropped)="onFilesDropped($event)">
  <p class="title">{{ name }} {{ required ? '*' : '' }}</p>
  <div class="dragAndDropContainer">
    <div class="filesList">
      <div *ngIf="file; else addedFiles" class="fileContainer">
        <div class="flex">
          <mat-icon class="pdfIcon">picture_as_pdf</mat-icon>
          <p class="fileName">{{file}}</p>
        </div>          
        <span (click)="onRemoveLoadedFile()">
          <i class="fa fa-trash fa-lg trashIcon"></i>
        </span>
      </div>
      <ng-template #addedFiles>
        <div class="fileWrapper" *ngFor="let file of files; index as i">
          <div class="fileContainer">
            <div class="flex mr-2">
              <mat-icon class="pdfIcon">picture_as_pdf</mat-icon>
              <div class="fileNameContainer">
                <p class="name"><strong>{{file.name}}</strong></p> 
                <p class="size">{{formatBytes(file.size)}}</p>
              </div>
            </div>
            <div class="iconsContainer">
              <span (click)="onRemoveFile(i, file.name)">
                <i class="fa fa-trash fa-lg trashIcon"></i>
              </span>
              <span (click)="generatePreview(abbr, i)">
                <mat-icon class="previewIcon">preview</mat-icon>
              </span>
            </div>
          </div>
          <div [ngClass]="abbr + '-' + i" style="display: flex; justify-content: center;"></div>
        </div>
      </ng-template>
    </div>
    
    <div class="ctaContainer" *ngIf="(!file && !files.length && !multiple) || multiple">
      <p class="text textAlt">Drag and drop {{abbr}} file here <br>
       <em>or</em>
      </p>
      <div>
        <button type="button" mat-stroked-button color="primary" (click)="fileInput.click()">Select File</button>
      </div>
    </div>
  </div>
  <input
    accept="application/pdf"
    class="fileInput"
    #fileInput
    hidden
    (change)="generateFileURI($event)"
    [multiple]="multiple"
    type="file"
  >
  <div class="previewContainer"></div>
</div>
