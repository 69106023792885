import { Injectable } from '@angular/core';
import {
  getAccountImagesQuery,
  getAccountSettingsQuery,
  getAccountStatisticsQuery,
  getContentQuery,
  getWelcomeContentQuery,
  updateAccountSettingsMutation,
  updateConfigurationMutation,
  updateCustomizationMutation,
  updateLicensingMutation,
  updateWelcomeContentMutation,
  uploadAttachmentMutation,
  deleteAttachment,
} from '../graphql/settings.graphql';
import {
  GraphResponse,
  GraphqlService,
} from '../../../core/services/graphql/graphql.service';
import { BehaviorSubject, lastValueFrom, map, Observable } from 'rxjs';
import { AccountImage, attachmentByFolder } from '../models/account-images.model';
import { AppService } from '../../../core/services/app/app.service';
import { CookieService } from 'ngx-cookie-service';
import {
  AccountSettings,
  QPAccountSettings,
} from '../../../public/account/models/account-settings.model';
import { Account } from 'src/app/public/account/models/account.model';
import { ErrorService } from 'src/app/core/services/error/error.service';

export type getAccountSettingsType = {
  accountSetting: AccountSettings;
};

export type manageAccountSettingsType = {
  manageAccountSettings: AccountSettings;
};

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  queries = {
    getContent: getContentQuery,
    getAccountSettings: getAccountSettingsQuery,
    getAccountStatistics: getAccountStatisticsQuery,
    getAccountImages: getAccountImagesQuery,
  }; //reportsCreatedUsingAPI

  mutations = {
    UpdateWelcomeContent: updateWelcomeContentMutation,
    UpdateAccountSettings: updateAccountSettingsMutation,
    UpdateLicensing: updateLicensingMutation,
    UpdateCustomization: updateCustomizationMutation,
    UpdateConfiguration: updateConfigurationMutation,
    uploadAttachment: uploadAttachmentMutation,
    DeleteAttachment: deleteAttachment,
  };

  constructor(
    private graphService: GraphqlService,
    private appService: AppService,
    private errorService: ErrorService
  ) {}

  removeAttachment(
    attachmentId: number
  ): Observable<GraphResponse<{ deleteAttachment: any }>> {
    return this.graphService.mutate<{ deleteAttachment: any }>({
      mutation: this.mutations.DeleteAttachment,
      variables: { attachmentId },
    });
  }
}
