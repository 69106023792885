import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, map, Observable, of, Subject } from 'rxjs';
import { EntityOption } from 'src/app/components/entity-select/models/entity-option.model';
import {
  GraphqlService,
  GraphResponse,
} from 'src/app/core/services/graphql/graphql.service';
import { BuildingsTestData } from 'src/app/data/buildings.data';
import { SystemsTestData } from 'src/app/data/systems.data';
import { User } from 'src/app/feature-modules/users/models/user.model';
import { QPFacilityWithParents } from '../../facilities/services/facility.service';
import { Building, BuildingAccessInput } from '../models/building.model';

const QPBuildingLite = `
  id
  name
  notes
`;

@Injectable({
  providedIn: 'root',
})
export class BuildingService {
  buildings: Subject<EntityOption[]> = new Subject();
  buildings$ = this.buildings.asObservable();

  queries: { list: string; getBuilding: string; getBuildingAccess: string } = {
    list: `query Buildings($facilityId: Int, $idList: [Int!]) {
      buildings(facilityId: $facilityId, idList: $idList) {
        ... on Building {
          ${QPBuildingLite}
        }
        ... on InvalidParametersError {
          code
          message
        }
        ... on NotFoundError {
          code
          message
        }
        ... on ConflictError {
          code
          message
        }
      }
    }`,
    getBuilding: `query Building($buildingId: Int!) {
      building(id: $buildingId) {
        ... on Building {
          accountNumber
          ${QPBuildingLite}
          facility {
            id
            name
          }
        }
      }
    }`,
    getBuildingAccess: `query QueryBuildingAccess($facilityId: Float!, $customerId: Float!, $buildingId: Float!) {
      buildingAccess(facilityId: $facilityId, customerId: $customerId, buildingId: $buildingId) {
        ... on User {
          id
          firstName
          lastName
        }
        ... on InvalidParametersError {
          message
          code
        }
        ... on ForbiddenError {
          message
          code
        }
        ... on NotFoundError {
          message
          code
        }
        ... on ConflictError {
          message
          code
        }
        ... on UnprocessableEntityError {
          message
          code
        }
      }
    }`,
  };
  mutations: { manageBuilding: string } = {
    manageBuilding: `mutation ManageBuilding($data: ManageBuildingInput!) {
      manageBuilding(data: $data) {
        ... on Building {
          id
          name
          notes
          accountNumber
        }
        ... on InvalidParametersError {
          message
          code
        }
        ... on NotFoundError {
          message
          code
        }
        ... on ConflictError {
          message
          code
        }
        ... on NotAllowed {
          message
          code
        }
      }
    }`,
  };

  constructor(
    private http: HttpClient,
    private graphService: GraphqlService
  ) {}

  getBuildings(facilitiyId: number): Observable<EntityOption[]> {
    const res = this.http.get(`/api/facility/${facilitiyId}/buildings`).pipe(
      map((val) => {
        const buildings = val as EntityOption[];
        this.buildings.next(buildings);
        return buildings;
      })
    );

    return res;
  }

  getBuilding(id: number): Promise<Building> {
    return lastValueFrom(
      this.graphService
        .query<{ building: Building }>({
          query: this.queries.getBuilding,
          variables: {
            buildingId: id,
          },
        })
        .pipe(
          map((res: GraphResponse<{ building: Building }>) => {
            //console.log('building response: ', res);
            let ret: any = {};
            if (res.errors) {
              throw res.errors;
            }
            if (res.data && res.data.building) {
              ret = res.data.building;
            }
            return ret;
          })
        )
    );
  }

  getBuildingWithParents(id: number): Promise<Building> {
    return lastValueFrom(
      this.graphService
        .query<{ building: Building }>({
          query: `query Building($buildingId: Int!) {
            building(id: $buildingId) {
              ... on Building {
                accountNumber
                ${QPBuildingLite}
                systems {
                  id
                  name
                }
                facility {
                  ${QPFacilityWithParents}
                }
              }
            }
          }`,
          variables: {
            buildingId: id,
          },
        })
        .pipe(
          map((res: GraphResponse<{ building: Building }>) => {
            //console.log('building response: ', res);
            let ret: any = {};
            if (res.errors) {
              throw res.errors;
            }
            if (res.data && res.data.building) {
              ret = res.data.building;
            }
            return ret;
          })
        )
    );
  }

  getBuildingAccess(vars: BuildingAccessInput): Promise<User[]> {
    return lastValueFrom(
      this.graphService
        .query<any>({
          query: this.queries.getBuildingAccess,
          variables: vars,
        })
        .pipe(
          map((res: GraphResponse<{ buildingAccess: User[] }>) => {
            let ret: any;
            if (res.data && res.data.buildingAccess) {
              ret = res.data.buildingAccess;
            }
            return ret;
          })
        )
    );
  }

  delete(id: number): Promise<boolean> {
    return lastValueFrom(
      this.graphService
        .mutate<{ deleteBuilding: any }>({
          mutation: `mutation DeleteBuilding($deleteBuildingId: Float!) {
          deleteBuilding(id: $deleteBuildingId)
        }`,
          variables: {
            deleteBuildingId: id,
          },
        })
        .pipe(
          map((res: GraphResponse<{ deleteBuilding: any }>) => {
            let ret = false;
            if (res.errors) {
              throw res.errors;
            }
            if (res.data && res.data.deleteBuilding) {
              ret = true;
            }
            return ret;
          })
        )
    );
  }
}
