import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService } from 'src/app/core/auth/auth/auth.service';
import { AppService } from 'src/app/core/services/app/app.service';
import { GraphqlService } from 'src/app/core/services/graphql/graphql.service';
import { User } from 'src/app/feature-modules/users/models/user.model';
import { Account } from './models/account.model';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
})
export class AccountComponent implements OnInit {
  user: User;
  accounts: Account[] = [];
  accountSelection: Account;

  doSwitch = false;

  constructor(
    private authService: AuthService,
    private appService: AppService,
    private graphService: GraphqlService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.appService.sidenavToggle.emit(false);
    this.loadAccounts();
    this.activatedRoute.params.subscribe((params) => {
      this.doSwitch = params['switch'] || false;
    });
  }

  loadAccounts() {
    const authStatus = this.authService.status.getValue();
    const user = authStatus.user;
    if (user) {
      this.user = user;
      this.accounts = authStatus.user?.accounts || [];
    }
  }

  selectCompare(c1: { id: number | string }, c2: { id: number | string }): boolean {
    return c1 && c2 && c1.id === c2.id;
  }

  submit() {
    if (this.doSwitch) {
      this.appService.resetEntities();
    }
    this.authService.chooseAccount(this.accountSelection.id, this.user);
  }
}
